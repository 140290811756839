<template>
  <div v-if="loaded && $page.props.baseRoute">
    <beekman-tooltip v-if="visible && favorites.length < maxFavorites" :content="$trans('global.favorites.markAsFavorite')" placement="left" @click="save">
      <i class="fas fa-lg fa-star text-yellow-500 cursor-pointer"></i>
    </beekman-tooltip>
    <beekman-tooltip :style="'error'" v-if="!visible" :content="$trans('global.favorites.deleteMarkAsFavorite')" placement="left" @click="remove">
      <i class="fas fa-xl fa-star-shooting text-red-500 cursor-pointer"></i>
    </beekman-tooltip>
  </div>
</template>

<script>
import BeekmanTooltip from "@ui/Components/Tooltip.vue";
import { mapActions, mapState } from 'pinia';
import { favoritesStore } from '@favorites/Stores/Favorites.js';

export default {
  components: {
    BeekmanTooltip,
  },
  data() {
    return {
      saved: false,
    };
  },
  created() {
    this.fetchFavorites();
  },
  computed: {
    ...mapState(favoritesStore, ['favorites', 'loaded', 'maxFavorites']),
    visible() {
      if (this.favorites.length) {
        return this.current() === null ? true : false;
      }

      return true;
    }
  },
  methods: {
    ...mapActions(favoritesStore, {
      fetchFavorites: 'fetch',
      addFavorite: 'add',
      deleteFavorite: 'delete'
    }),
    current() {
      const found = this.favorites.filter((favorite) => {
        if (this.$page.url && window.location.origin) {
          return this.$route(`${favorite.base_route}.index`) === `${window.location.origin}${this.$page.url}`;
        }
        return false;
      });

      if (found.length) {
        return found[0];
      }
      return null;
    },
    save() {
      axios.post(this.$route('favorites.store'), {
        breadcrumbs: this.$page.props.breadcrumbs,
        base_route: this.$page.props.baseRoute,
        query_string: window.location.search,
        url_params: Object.fromEntries(new URLSearchParams(window.location.search)),
      }).then((response) => {
          this.addFavorite(response.data.props);
          this.$flashSuccess(response.data.message);
        });
    },
    remove() {
      const exists = this.current();

      if (exists.id) {
        axios.delete(this.$route('favorites.destroy', {id: exists.id})).then((response) => {
          if (response.data.success) {
            this.deleteFavorite(exists.id);
            this.$flashSuccess(response.data.message);
          } else {
            this.$flashError(response.data.message);
          }
        });
      }
    },
  },
}
</script>
