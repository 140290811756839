<template>
  <div class="mx-2 relative inline-block flex items-center" style="height: 3.2rem">
    <a :href="route('exports.index')">
      <beekman-tooltip :style="'success'" content="Exports">
        <i class="fal fa-download text-themeText text-xl cursor-pointer"/>
        <div v-if="count" class="absolute top-1 left-2 items-center justify-center bg-red-600 rounded-full h-4">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span class="relative pl-1 pr-1.5 py-1 text-red-100" style="line-height: 0.6rem; font-size: 0.7rem">{{ count }}</span>
        </div>
      </beekman-tooltip>
    </a>
  </div>
</template>

<script>

import BeekmanTooltip from "@ui/Components/Tooltip.vue";

export default {
  components: {BeekmanTooltip},
  computed: {
    count() {
      if (this.openExports < 100) {
        return this.openExports;
      }
      return '99+';
    },
  },
  methods: {
    fetchOpenExports() {
      window.axios.get(route('export.user.openExportCount', {userId: this.$user.id})).then((response) => {
        if (response && response.data) {
          this.openExports = response.data.numberOfOpenExports;
        }
      });
    },
    initBroadcast() {
      if (this.$user && this.$user.channel) {
        window.Echo.private(this.$user.channel)
            .listen('.export.update', (event) => {
              this.fetchOpenExports();
            });
      }
    },
  },
  created() {
    this.fetchOpenExports();
    this.initBroadcast();
  },
  beforeUnmount() {
    if (this.$user && this.$user.channel) {
      window.Echo.leaveChannel(this.$user.channel);
    }
  },
  data() {
    return {
      openExports: 0,
    };
  }
};

</script>
