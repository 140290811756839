<template>
  <div class="text-themeText p-4 flex justify-between items-center rounded-t header"
       :class="{'bg-theme border-b border-gray-200': !error, 'bg-red-500': error}"
       :style="applicationHeader ? `background: ${applicationHeader.color}; color: ${applicationHeader.text};` : ''">
    <div class="copy-modal-id" @dblclick="copyModalId(showModalId)" v-if="showModalId">ID: {{showModalId}}</div>
    <div class="text-lg z-50">
      <img v-if="applicationHeader" class="logo" :src="applicationHeader.image" />
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  emits: ['loading'],
  props: {

    error: {
      default: false,
    },
    buttonsDisabled: {
      default: true
    },
    hasLock: {
      default: false
    },
    applicationHeader: {
      default: null
    },
    showModalId: {
      default: null
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    loading(loading) {
      this.isLoading = loading;
      this.$emit('loading', loading);
    },
    copyModalId(value) {
      if (value !== '') {
        // Create new temp element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = value;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {display: 'none'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
        EventBus.$emit('flash-copied', value);
      }
    },
  }
}
</script>
