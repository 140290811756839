<template>
    <div>
        <button type="submit"
                class="block w-full p-2 text-left uppercase text-xxs tracking-tight"
                v-if="as == 'button'">
            <slot></slot>
        </button>

        <inertia-link :href="href" :headers="$inertiaHeaders" class="block p-2 uppercase text-xxs tracking-tight" v-else>
            <slot></slot>
        </inertia-link>
    </div>
</template>

<script>
    export default {
        props: ['href', 'as']
    }
</script>
