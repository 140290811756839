export default {
    methods: {
        searchable(data, fields, term, type = '') {
            let filteredData = data;
            fields.forEach(field => {
                filteredData = filteredData.filter((row) => {
                    let value = row[field] ? row[field].toString().toLowerCase() : '';
                    if (typeof row.groupTitle != 'undefined') {
                        return row;
                    }
                    if (type == 'startsWith') {
                        return value.startsWith(term.toLowerCase());
                    }
                    if (type == 'endsWith') {
                        return value.endsWith(term.toLowerCase());
                    }
                    return value.includes(term.toLowerCase());
                });
            });
            return filteredData;
        },
        exactMatch(data, field, term) {
            return data.filter((row) => {
                return row[field] === term;
            });
        },
        notEmpty(data, field) {
            return data.filter((row) => {
                return !!row[field];
            });
        },
    },
};
