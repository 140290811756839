<template>
  <div v-if="componentLoaded">
    <Component :is="editComponent"
               :canEdit="data.forms.update.form.canEdit"
               :canDelete="data.forms.update.form.canDelete"
               :canBeRestored="data.forms.update.form.canBeRestored"
               :editableRoute="data.forms.update.baseRoute"
               :data="data"
               :form="data.forms.update"
               :locks="data.locks"
               :show="data.forms.update !== null"
               :url="this.url"
               :user="this.$user"
               :actioncentreItem="actioncentreItem"
               @abort="closeEditModal"
               @submit="submitEditModal"/>
  </div>
</template>

<script>
import ImportComponent from "@crud/Mixins/ImportComponent.js";

export default {
  mixins: [ImportComponent],
  data() {
    return {
      data: null,
      componentLoaded: false,
    };
  },
  emits: ['close', 'submit', 'loading'],
  props: {
    url: {
      default: ''
    },
    actioncentreItem: {
      default: null
    },
  },
  created() {
    this.componentLoaded = false;
    if (this.canOpenModal(this.url)) {
      this.$emit('loading', true);
      axios.get(this.url)
          .then((response) => {
            this.componentLoaded = true;
            this.data = response.data;
            this.$emit('loading', false);
          }).catch((error) => {
        this.componentLoaded = true;
        this.$emit('loading', false);
      });
    }
  },
  computed: {
    editComponent() {
      return this.importComponent('Modals/Edit', 'crud');
    },
  },
  methods: {
    canOpenModal(url) {
      if (window.modals.list.indexOf(url) === -1) {
        return true;
      } else {
        this.$flashError('Pagina is al geopend');
        return false;
      }
    },
    closeEditModal() {
      this.componentLoaded = false;
      this.$emit('close');
    },
    submitEditModal(props) {
      props['closeAfterUpdate'] = this.data.forms.update.closeAfterUpdate;
      if (this.data.forms.update.closeAfterUpdate) {
        this.componentLoaded = false;
      }
      this.$emit('submit', props);
    },
  },
}
</script>
