<template>
  <template v-if="Object.keys(messages).length">
  <div v-if="!fullscreen" class="flash-messages fixed right-0 m-6" :style="`top: ${top}`" style="z-index: 500">
    <transition-group name="slide-fade" tag="div">
      <beekman-flash-message v-for="message in messages"
                             :message="message"
                             :key="message.id"
                             class="mb-2"/>
    </transition-group>
  </div>
  <div v-if="fullscreen" style="z-index: 990"
       class="fixed inset-0 sm:p-0 active-modal">
    <div class="fixed inset-0 transform transition-all modal-overlay">
      <div class="absolute w-full h-full bg-gray-500 opacity-50"></div>
    </div>
    <div class="flex h-screen w-full justify-center items-center">
      <div class="modal width-auto height-auto">
        <div class="flash-messages" style="z-index: 500">
          <transition-group name="slide-fade" tag="div">
            <beekman-flash-message v-for="message in messages"
                                   :message="message"
                                   :key="message.id"
                                   class="mb-2"/>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
  </template>
</template>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}
</style>

<script>
  import BeekmanFlashMessage from '@ui/Components/FlashMessage.vue';
  import EventBus from '@ui/eventBus.js';
  export default {
    data() {
      return {
        messages: {},
      }
    },
    components: {
      BeekmanFlashMessage,
    },
    props: {
      top: {
        type: String,
        default: '',
      },
      flash: {

      },
      fullscreen: {
        type: Boolean,
        default: false,
      }
    },
    created() {
      // error
      if (this.flash && this.flash.error.length) {
        this.addMessage({
          id: this.uuid(),
          type: 'error',
          text: this.flash.error,
          seconds: 5
        });
      }
      EventBus.$on('flash-error', (message, seconds=5) => {
        if (message) {
          this.addMessage({
            id: this.uuid(),
            type: 'error',
            text: message,
            seconds: seconds
          });
        }
      });
      // success
      if (this.flash && this.flash.success.length) {
        this.addMessage({
          id: this.uuid(),
          type: 'success',
          text: this.flash.success,
          seconds: 5
        });
      }
      EventBus.$on('flash-success', (message, seconds=5) => {
        if (message) {
          this.addMessage({
            id: this.uuid(),
            type: 'success',
            text: message,
            seconds: seconds
          });
        }
      });
      EventBus.$on('flash-copied', (value) => {
        this.addMessage({
          id: this.uuid(),
          type: 'success',
          text: 'Gekopieerd: ' + value,
          seconds: 0.5
        });
      });
      EventBus.$on('flash-destroy', (messageId) => {
        this.delete(messageId);
      });
    },
    methods: {
      addMessage(addMessage) {
        let found = -1;
        if (Object.keys(this.messages).length) {
          found = Object.values(this.messages).findIndex(function (message) {
            return message.text === addMessage.text
          });
        }
        if (found < 0) {
          this.messages[addMessage.id] = addMessage;
        }
      },
      delete(messageId) {
        delete this.messages[messageId];
      },
      uuid() {
        let uuid = "", i, random;
        for (i = 0; i < 32; i++) {
          random = Math.random() * 16 | 0;

          if (i == 8 || i == 12 || i == 16 || i == 20) {
            uuid += "-"
          }
          uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
        }
        return `flash-${uuid}`;
      },
    },
  };
</script>
