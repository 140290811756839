<template>
  <span :class="classes" class="badge">
    {{ badgeCount }}
  </span>
</template>

<script>

export default {
  props: {
    badgeCount: {
      type: [Number, String],
      required: true
    },
    classes: {
      type: String,
    },
  },
}
</script>
