<template>
  <div class="px-4 relative flex select" v-if="applications.length > 1">
    <beekman-dropdown align="right" width="48" :content-classes="['max-h-96']"
                      :forceClose="isOpen === false">
      <template #trigger="{ open }">
        <div class="relative inline-block flex items-center">
          <beekman-button styling="theme" class="selected">
            <div class="flex justify-between w-full items-center ellipsis">
              {{application.name}}
            </div>
            <span class="absolute right-2">
              <i :class="{
                'transform rotate-180 transition duration-150':open === true,
                'transform rotate-0 transition duration-150':open === false
              }" class="far fa-angle-down"/>
            </span>
          </beekman-button>
        </div>
      </template>

      <template #content>
        <div class="max-h-96 overflow-y-auto rounded bg-white">
          <div  class="rounded border border-b px-2 py-1 hover:opacity-80 cursor-pointer"
                :style="{background: application.color}"
                v-for="application in applications" :key="application.id"
                @click="applyApplication(application)">
            <img v-if="application.image" :src="application.image" class="pr-2 h-5" />
            <span v-else class="text-white">{{application.name}}</span>
          </div>
        </div>
      </template>
    </beekman-dropdown>
  </div>
</template>

<script>
import BeekmanDropdown from "@ui/Components/Dropdown.vue";
import BeekmanButton from "@ui/Components/Button.vue";
import EventBus from '@ui/eventBus.js';
import { mapState, mapActions } from 'pinia';
import { userStore } from '@ui/Stores/User.js';

export default {
  components: {
    BeekmanDropdown,
    BeekmanButton,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapState(
        userStore, ['application', 'applications'],
    ),
  },
  methods: {
    applyApplication(application) {
      const oldValue = this.application.id;
      this.setLookAndFeel(application);
      this.isOpen = false;
      window.application = application.id;
      this.$setApplication(application.id)
    },
    ...mapActions(userStore, {
      setLookAndFeel: 'setLookAndFeel'
    }),
  }
}
</script>
