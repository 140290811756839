import * as axiosHelpers from './axios.js';
import * as debugHelpers from './debug.js';
import EventBus from "@ui/eventBus.js";
import StackTrace from "stacktrace-js";
import _ from 'lodash';
import axios from 'axios';

// Global JS error handler
window.onerror = function (message, file, line, col, error) {
    let data = {
        type: 'js',
        message: message,
        file: file,
        line: line,
        col: col,
        error: error,
    };

    debugHelpers.logError(error, data);
};

window.shouldReloadForNewAssets = function () {
    let reload = true;
    if (typeof window.checkDatatableReloadForNewAssets === 'function') {
        reload = window.checkDatatableReloadForNewAssets();
    }
    // reload when we have not more then one open modal and no active locks
    if (window.modals.count < 2 && window.locks.hasLocks === 0 && reload) {
        if (window.response.shouldReload === 1) {
            window.response.shouldReload = 0;
            window.location.href = window.location.toString();
        }
    }
}

window._ = _;
// window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = axios;
// window.axios = require('axios');

window.axios.defaults.errorMessageConfig = {
    // The response status codes to message
    statusCodes: [503, 500, 429, 419, 404, 403, 401],
};

window.axios.defaults.retryConfig = {
    // Retry 3 times on requests that return a response (500, etc) before giving up.
    retry: 3,

    // Retry twice on errors that don't return a response (ENOTFOUND, ETIMEDOUT, etc).
    // 'noResponseRetries' is limited by the 'retry' value.
    noResponseRetries: 2,

    // Milliseconds to delay between retries
    retryDelay: 100,

    // HTTP methods to automatically retry. In UPPERCASE
    httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT'],

    // The response status codes to retry.  Supports a double
    // https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
    // 1xx - Retry (Informational, request still processing)
    // 2xx - Do not retry (Success)
    // 3xx - Do not retry (Redirect)
    // 4xx - Do not retry (Client errors)
    // 429 - Retry ("Too Many Requests")
    // 5xx - Retry (Server errors)
    // array with a list of ranges.
    statusCodesToRetry: [[100, 103], [429, 429], [501, 599]],

    // You can detect when a retry is happening, and figure out how many
    // retry attempts have been made
    onRetryAttempt: (attempts, error) => {
        //console.log(`Retry attempt #${attempts - 1}`, error);
    }
};
// Custom settings per request
// axios.get(URL, {
//     retryConfig: {
//         retry: 5, 5 pogingen ipv 3
//         retry: 0, geen retries
//     }
// })


window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Accept'] = 'text/html, application/xhtml+xml';

window.axios.interceptors.response.use((response) => {

    window.shouldReloadForNewAssets();

    if (import.meta.env.VITE_PUSHER_ENV === 'local') {
        const contentType = response.headers['content-type'];
        // log dd or ddo dumps from the backend
        if (contentType.startsWith('text/html')) {
            EventBus.$emit('debug', {'dump': response.data});
        }
        EventBus.$emit('requestIsValid');
    }
    return response;
}, function (error) {
    // canceled? reject
    if (window.axios.isCancel(error)) {
        return Promise.reject(error);
    }
    // redirect as response?
    if (error.response && error.response.status === 307 && error.response.headers['x-redirect-type'] && error.response.headers['x-redirect-url']) {
        const type = error.response.headers['x-redirect-type'];
        const href = error.response.headers['x-redirect-url'];
        if (type === 'blank') {
            window.open(href, '_blank');
        } else {
            window.open(href);
        }
        return Promise.resolve();
    }
    // new assets version? Reload
    if (error.response && error.response.status === 409 && error.response.headers['x-inertia-location']) {
        window.response.shouldReload = 1;
        window.shouldReloadForNewAssets();
    }
    // outdated csrf token? Reload
    if (error.response && error.response.status === 419) {
        return axiosHelpers.refreshCsrf(error);
    }
    // handle error messages
    axiosHelpers.errorMessagesFromResponse(error);
    // Determine if we should retry the request
    if (!axiosHelpers.shouldRetryRequest(error)) {
        return Promise.reject(error);
    }
    return axiosHelpers.retry(error);
});

axios.interceptors.request.use(function (request) {
    request.headers['x-guid'] = window.name;
    if (!request.headers['X-Tries']) {
        request.headers['X-Tries'] = 1;
    }

    // add session data
    // get all items from sessionStorage
    let sessionStorageData = {};
    for (const [key, value] of Object.entries(sessionStorage)) {
        sessionStorageData[key] = value;
    }
    request.headers['x-session'] = btoa(JSON.stringify(sessionStorageData));
    // Do something before request is sent
    return request;
}, function (error) {
    // Do something with request error
    if (import.meta.env.VITE_PUSHER_ENV === 'local') {
        EventBus.$emit('flash-error', `Request error: ${error.response.status} - ${error.response.statusText}`);
    } else {
        EventBus.$emit('flash-error', 'Er ging iets fout bij het request, we zijn hiervan op de hoogte gebracht');
    }
    return Promise.reject(error);
});
