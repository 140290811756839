<template>
  <div class="bg-gray-50 border-t rounded-b">
    <div class="flex p-4 items-center justify-between">
      <slot name="footer"></slot>
      <div class="flex flex-1 items-center justify-end modal-footer-buttons">
        <slot name="footerButtons"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BeekmanButton from '@ui/Components/Button.vue';

export default {
  components: {
    BeekmanButton,
  },
}
</script>
