import {defineStore} from 'pinia';

var swapArrayElements = function (array, from, to) {
    if (array.length === 1) {
        return array;
    }
    array.splice(from, 1, array.splice(to, 1, array[from])[0]);

    return array;
};

export const favoritesStore = defineStore('favorites', {
    state: () => ({
        favorites: [],
        loaded: false,
        maxFavorites: 20,
    }),
    actions: {
        async fetch() {
            window.axios.get(route('favorites.index')).then((response) => {
                this.loaded = true;
                if (response && response.data) {
                    this.favorites = response.data;
                }
            });
        },
        move(from, to, oldDragData) {
            this.favorites = swapArrayElements(this.favorites, from, to);
        },
        add(favorite) {
            this.favorites.push(favorite);
        },
        delete(id) {
            this.favorites.splice(
                this.favorites.findIndex((favorite) => favorite.id === id),
                1
            );
        }
    },
})
