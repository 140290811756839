<template>
  <beekman-overlay-modal v-if="componentLoaded" :props="componentProps" @close="closeModal" @refresh="refresh" :url="this.url" :tab-has-lock="tabHasLock"/>
</template>

<script>
  import BeekmanOverlayModal from '@ui/Components/Modals/OverlayModal.vue'

  export default {
    components: {
      BeekmanOverlayModal,
    },
    data() {
      return {
        componentProps: null,
        componentLoaded: false,
      };
    },
    emits: ['close', 'loading'],
    props: {
      url: {
        default: ''
      },
      tabHasLock: {
        type: Boolean,
        default: false
      },
    },
    created() {
      this.componentLoaded = false;
      this.load();
    },
    methods: {
      load() {
        this.componentLoading = true;
        this.$emit('loading', true);
        axios.get(this.url).then((response) => {
          this.componentProps = response.data;
          this.componentLoaded = true;
          this.$emit('loading', false);
        }).catch((error) => {
          this.$emit('loading', false);
        });
      },
      closeModal() {
        this.componentLoaded = false;
        this.$emit('close');
      },
      refresh() {
        this.load();
      }
    },
  }
</script>
