import EventBus from '@ui/eventBus.js';
export default {
    install(app, options) {

        // flash error
        app.config.globalProperties.$flashError = function (message, seconds=5) {
            EventBus.$emit('flash-error', message, seconds);
        }
        // flash success
        app.config.globalProperties.$flashSuccess = function (message, seconds=5) {
            EventBus.$emit('flash-success', message, seconds);
        }
        // flash success
        app.config.globalProperties.$flashDelete = function (id) {
            EventBus.$emit('flash-destroy', id);
        }
        // show debug
        app.config.globalProperties.$debug = function (data) {
            EventBus.$emit('debug', data);
        }
    }
}
