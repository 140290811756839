<template>
  <modal v-if="show" :closeable="closeable" :height="height" :position="position" :show="show" :url="url" :width="width"
         @close="closeModal" @refresh="refresh">
    <Component
        :is="modalComponent"
        ref="modal"
        :form="props.data.form"
        :modal-data="props"
        :url="url"
        @close="closeModal"
        :set-tab-has-lock="tabHasLock"
        @submitted="submitted()"
        @refresh="refresh()"
    />
  </modal>
</template>

<script>
import Modal from '@ui/Components/Modals/Modal.vue';
import ImportComponent from "@crud/Mixins/ImportComponent.js";

export default {
  mixins: [ImportComponent],
  components: {
    Modal,
  },
  data() {
    return {
      show: true,
    }
  },
  emits: ['close', 'refresh'],
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
    closeable: {
      default: false
    },
    url: {
      default: ''
    },
    tabHasLock: {
      type: Boolean,
      default: false
    },
    component: {
      type: String,
      required: true
    },
    componentFrom: {
      type: String,
      required: true
    },
  },
  computed: {
    modalComponent() {
      return this.importComponent(this.component, this.componentFrom);
    },
    width() {
      return this.props.width;
    },
    height() {
      return this.props.height;
    },
    position() {
      return this.props.position;
    },
  },
  methods: {
    submitted() {
      this.$emit('refresh');
    },
    refresh() {
      this.$emit('refresh');
    },
    closeModal() {
      this.show = false;
      if (this.props.redirectOnClose) {
        this.$inertia.visit(this.props.redirectOnClose, {
          headers: {
            'persistFilters': JSON.stringify(this.$page.props.persistFilters)
          },
        });
      } else {
        this.$emit('close');
      }
    },
    uuid() {
      let uuid = "", i, random;
      for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;

        if (i == 8 || i == 12 || i == 16 || i == 20) {
          uuid += "-"
        }
        uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
      }
      return uuid;
    },
  }
}
</script>
