<template>
  <div v-tooltip="{ content: content, html: html, placement: placement, theme: style, shown: shown, triggers: triggers }"><slot/></div>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "BeekmanTooltip",
  props: {
    /**
     * Preferred placement (the "auto" placements will choose the side with most space.)
     */
    html: {
      type: Boolean,
      default: false,
    },
    /**
     * Preferred placement (the "auto" placements will choose the side with most space.)
     */
    placement: {
      type: String,
      default: "top",
    },
    /**
     * Content to display
     */
    content: {
      type: String,
      default: '',
    },
    /**
     * Content to display
     */
    style: {
      type: String,
      default: 'info',
    },

    shown: {
      type: Boolean,
      default: false,
    },

    triggers: {
      type: Array,
      default: ['hover'],
    },
  },
});
</script>
