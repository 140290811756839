import BeekmanSearchable from "@ui/Mixins/Searchable.js";
import {cloneDeep} from 'lodash';

export default {
    mixins: [
        BeekmanSearchable,
    ],
    props: {
        label: {
            default: 'selecteer',
        },
        options: {
            default() {
                return {};
            }
        },
        selectedValue: {
            default: '',
        },
        filterable: {
            default: false,
        },
        searchUrl: {
            default: '',
        },
        minChars: {
            default: 1,
        },
    },
    data() {
        return {
            value: this.selectedValue,
            filterTerm: '',
            filtered: [],
            searchTerm: '',
            isSearching: false,
        };
    },
    methods: {
        filter() {
            if (this.options.length) {
                if (this.filterTerm.length >= this.minChars) {
                    let result = this.searchable(this.options, ['title'], this.filterTerm);
                    if (result.length == 0 && this.addValueWhenNoResult) {
                        this.selectLabel = this.filterTerm;
                        this.value = this.filterTerm;
                        this.$emit('selected', this.value);
                    }
                    this.filtered = result;
                } else {
                    this.filtered = this.options;
                }
            }
        },
        searching() {
            this.search();
        },
        search: window._.debounce(function () {
            if (this.searchTerm.length >= this.minChars) {
                this.isSearching = true;
                axios.get(this.searchUrl, {params: {'search': this.searchTerm}})
                    .then((response) => {
                        this.isSearching = false;
                        this.filtered = response.data;
                    });
            } else {
                this.isSearching = false;
                this.filtered = [];
            }
        }, 300),
    },
}
