<template>
    <li v-if="href">
      <inertia-link :href="href" :headers="$inertiaHeaders">
        <i class="ml-2 mr-1 fal fa-angle-double-right"></i>
        <span class="text-gray-500 hover:text-theme">
          <slot></slot>
        </span>
      </inertia-link>
    </li>
    <li v-else>
      <i class="ml-2 mr-1 fal fa-angle-double-right"></i>
      <span class="text-gray-500">
          <slot></slot>
      </span>
    </li>
</template>

<script>
export default {
  props: ['href'],
}
</script>

