export default {
    props: {
        focusElement: [Boolean, Number],
        setFocusElement: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.focusElement && typeof this.$refs.input != 'undefined') {
            this.$refs.input.focus();
        }
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        },
    },
    watch: {
        setFocusElement(newValue, oldValue) {
            if (newValue && typeof this.$refs.input != 'undefined') {
                this.$refs.input.focus();
            }
        }
    },
};
