export default {
    methods: {
        /**
         * Translate the given key.
         */
        __(key, replace = {}) {
            return this.$trans(key, replace);
        },
        trans(key, replace = {}) {
            return this.$trans(key, replace);
        }
    },
};
