import EventBus from "@ui/eventBus";
import {reactive} from "vue";

function sessionStorageData()
{
    let sessionStorageData = {};
    for (const [key, value] of Object.entries(sessionStorage)) {
        sessionStorageData[key] = value;
    }
    return btoa(JSON.stringify(sessionStorageData));
}

export default {
    install(app, options) {
        // user property
        app.config.globalProperties.$user = [];
        app.config.globalProperties.$locale = [];
        app.config.globalProperties.$isDarkMode = false;
        app.config.globalProperties.$userPreferences = {};
        app.config.globalProperties.$inertiaHeaders = reactive({
            'x-guid': window.name,
            'x-session': sessionStorageData(),
        });
        app.config.globalProperties.$setApplication = function (application) {
            app.config.globalProperties.$inertiaHeaders['x-session'] = sessionStorageData();
        }
        app.config.globalProperties.$getSessionStorage = function () {
            return sessionStorageData();
        }
        // user methods
        app.config.globalProperties.$hasUser = function () {
            return Object.keys(app.config.globalProperties.$user).length > 0;
        }
        app.config.globalProperties.$addUser = function (user) {
            app.config.globalProperties.$user = user;
            if (user.preferences) {
                app.config.globalProperties.$userPreferences = user.preferences;
            }
        }
        app.config.globalProperties.$addLocale = function (locale) {
            app.config.globalProperties.$locale = locale;
        }
        app.config.globalProperties.$addUserPreference = function (name, value, asList = false) {
            // copy data
            let preferences = app.config.globalProperties.$userPreferences;
            // // add data
            if (asList) {
                // force object
                if (typeof preferences[name] !== 'object') {
                    preferences[name] = {};
                }
                // only add when value is an object
                if (typeof value === 'object') {
                    for (const [objectKey, objectValue] of Object.entries(value)) {
                        preferences[name][objectKey] = objectValue;
                    }
                }
            } else {
                preferences[name] = value;
            }

            // post data
            return axios.post(this.$route('system-management.users.updatePreferences', {id: app.config.globalProperties.$user.id}), {
                preferences,
            }).then(() => {
                app.config.globalProperties.$userPreferences = preferences;
                return Promise.resolve(preferences);
            });
        }
        app.config.globalProperties.$getUserPreference = function (key) {
            return app.config.globalProperties.$userPreferences[key];
        }
    }
}
