<template>
    <p v-show="show" class="form-error">
        {{ message }}
    </p>
</template>

<script>
    export default {
        props: ['message', 'show']
    }
</script>
