<template>
  <div class="bg-white border-b relative hover:bg-gray-100"
       style="min-width: 300px">
    <div class="flex flex-row p-3 pb-0">
      <div class="w-1/12 mr-3 flex flex-col items-center justify-center">
        <i class="text-xl" :class="iconClasses"></i>
      </div>
      <div v-if="notification.data" class="space-y-1 w-10/12">
        <div class="text-md font-medium"
             v-html="notification.data.title">
        </div>
        <div class="text-xs text-gray-600"
              v-html="notification.data.text">
        </div>
        <div v-if="notification.data.link" class="text-theme mt-1">
          <a class="button bg-theme text-white" :href="notification.data.link.url">
            <i v-if="notification.data.link.icon" :class="notification.data.link.icon" class="mr-2"></i>
            {{notification.data.link.text ?? 'Bekijk'}}
          </a>
        </div>
      </div>
      <div class="w-1/12">
        <beekman-tooltip :content="$trans('global.notifications.markAsRead')" placement="left">
          <i class="text-md fa fa-eye-slash text-gray-300 hover:text-gray-600 cursor-pointer"
             @click="markAsRead"></i>
        </beekman-tooltip>
      </div>
    </div>
    <div v-if="notification.created_at" class="p-3 pt-0 flex justify-end text-gray-400 text-xxs mt-1">
      {{this.$filters.timeago(notification.created_at)}}
    </div>
  </div>
</template>

<script>
import BeekmanTooltip from "@ui/Components/Tooltip.vue";

export default {
  emit: ['markedAsRead'],
  props: ['notification'],
  components: {
    BeekmanTooltip,
  },
  computed: {
    iconClasses() {
      let classes = [];
      if (this.notification.data) {
        if (this.notification.data.icon) {
          classes.push(`${this.notification.data.icon} text-theme`);
        } else {
          if (this.notification.data.type === 'error') {
            classes.push('text-red-500 fal fa-bell-exclamation');
          }
          if (this.notification.data.type === 'success') {
            classes.push('text-green-500 fal fa-check-circle');
          }
        }
      }
      return classes;
    }
  },
  methods: {
    markAsRead() {
      window.axios.post(route('notifications.markAsRead', {id: this.notification.id})).then((response) => {
        if (response && response.data && response.data.success) {
          this.$emit('markedAsRead', this.notification.id);
        }
      });
    },
  },
};
</script>
