import {defineAsyncComponent} from 'vue';
import {asyncBeekmanResources, asyncGisResources, appPath, modulePath, customModulePath, customModules, customBeekmanResources} from "@ui/setup.js";

export default {
    methods: {
        importComponent(path, type = '') {
            let beekmanModules = null;
            let componentPath = '';
            if (path.length) {
                if (customModules().includes(type)) {
                    beekmanModules = customBeekmanResources(type);
                    componentPath = `${customModulePath()}/${type}/Resources/js/${path}.vue`;
                } else if (type !== '') {
                    beekmanModules = asyncBeekmanResources();
                    componentPath = `${modulePath()}/${type[0].toUpperCase()}${type.slice(1)}/Resources/js/${path}.vue`;
                } else {
                    beekmanModules = asyncGisResources();
                    componentPath = `${appPath()}/${path}.vue`;
                }

                if (beekmanModules) {
                    if (beekmanModules[componentPath]) {
                        return defineAsyncComponent(() => {
                            return new Promise((resolve, reject) => {
                                const importComponent = beekmanModules[componentPath];
                                resolve(importComponent().then(module => module))
                            })
                        });
                    }
                    console.log(`No component found for: ${componentPath} in ${beekmanModules} for type ${type} and path ${path}`);
                }
            }
            return false;
        }
    }
};
