<template>
  <button ref="button" :class="[element, styling, 'disabled:cursor-not-allowed']" :type="type" @click="canOpen" :disabled="disabled">
    <slot></slot>
    <template v-if="openDynamicModal">
      <beekman-fetch-dynamic-edit-modal :url="url" @close="openDynamicModal = false" @loading="loading" @submit="submitEditModal"/>
    </template>
  </button>
</template>

<script>
import BeekmanFetchDynamicEditModal from '@ui/Components/Modals/FetchDynamicEditModal.vue';

export default {
  components: {
    BeekmanFetchDynamicEditModal,
  },
  emits: ['submit', 'loading'],
  props: {
    type: {
      type: String,
      default: 'button',
    },
    modalType: {
      type: String,
      default: 'dynamic',
    },
    element: {
      type: String,
      default: 'button',
    },
    styling: {
      type: [Object, String],
      default: 'primary',
    },
    tooltip: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.button.focus();
    }
  },
  data() {
    return {
      openDynamicModal: false,
    };
  },
  methods: {
    canOpen() {
      if (window.modals.list.indexOf(this.url) === -1) {
        this.openDynamicModal = true;
      } else {
        this.$flashError('Pagina is al geopend');
      }
    },
    submitEditModal(props) {
      if (props.closeAfterUpdate) {
        this.openDynamicModal = false;
      }
      this.$emit('submit', props);
    },
    loading(loading) {
      this.$emit('loading', loading);
    },
  }
}
</script>
