<template>
  <label :for="name" class="block flex justify-start" :title="explanation">
    <span v-if="value" class="ellipsis">{{ value }}</span>
    <span v-else class="ellipsis"><slot></slot></span>
    <span v-if="required || checkRequired()">*</span>
    <span v-if="props && props.comment" class="px-1.5 bg-gray-100 ml-auto rounded-full">
      <beekman-tooltip :content="props.comment" :html="true">
        <i class="fas fa-question"></i>
      </beekman-tooltip>
    </span>
  </label>
</template>

<script>
import BeekmanTooltip from "@ui/Components/Tooltip.vue";
import BeekmanButton from "@ui/Components/Button.vue";
import BeekmanConfirmButton from "@ui/Components/ConfirmButton.vue";
import BeekmanDynamicModalButton from "@ui/Components/Buttons/DynamicModalButton.vue";
import BeekmanEditModalButton from "@ui/Components/Buttons/EditModalButton.vue";
import BeekmanCreateModalButton from "@ui/Components/Buttons/CreateModalButton.vue";
import BeekmanLink from "@ui/Components/Buttons/Link.vue";
import EventBus from "@ui/eventBus.js";

export default {
  props: {
    name: String,
    explanation: String,
    value: String,
    validation: String,
    required: Boolean,
    props: {
      type:[Array, Object],
      default: {}
    },
    data: {
      type:[Array, Object],
      default: {}
    },
  },
  components: {
    BeekmanTooltip,
  },
  methods: {
    checkRequired() {
      if (typeof this.props.requiredWith !== 'undefined') {
        if (this.data[this.props.requiredWith] != '') {
          return true;
        }
      }
      if (typeof this.props.requiredIf !== 'undefined') {
        if (this.data[this.props.requiredIf['field']] == this.props.requiredIf['value']) {
          return true;
        }
      }
      return false;
    },
  },
}
</script>
