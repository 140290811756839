import { defineStore } from 'pinia';

export const userStore = defineStore('user', {
    state: () => ({
        theme: 'test',
        darkMode: false,
        application: {},
        applications: [],
    }),
    getters: {
        getApplicationById: (state) => {
            return (applicationId) => {
                return state.applications.find((application) => application.id === applicationId)
            }
        },
    },
    actions: {
        // any amount of arguments, return a promise or not
        setTheme(theme) {
            let dark = false;

            if (theme === 'system') {
                if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    dark = true;
                }
            }
            if (theme === 'dark') {
                dark = true;
            }
            this.theme = theme;
            this.darkMode = dark;
        },
        setApplications(applications) {
            this.applications = applications;
            let applicationId = sessionStorage.getItem("application");
            if (Object.keys(applications).length === 2 && (applicationId == 0 || applicationId == null)) {
                applicationId = Object.keys(applications)[Object.keys(applications).length - 1];
                this.setLookAndFeel(applications[applicationId]);
            }
            if (applicationId) {
                this.application = applications[applicationId];
                window.application = applicationId;
            } else {
                this.setLookAndFeel(applications[0]);
                window.application = applications[0].id;
            }
        },
        setLookAndFeel(application) {
            this.application = application;

            sessionStorage.setItem("application", application.id);
        },
    },
})
