<template>
  <button ref="button" :class="[element, styling, 'disabled:cursor-not-allowed']" :type="type" @click="canOpen" :disabled="disabled">
    <slot></slot>
    <template v-if="openDynamicModal">
      <beekman-fetch-dynamic-create-modal :url="url"
                                          @close="closeModal()"
                                          @selectApplications="this.$emit('selectApplications', $event)"
                                          :selectedApplicationId="selectedApplicationId"
                                          @submit="submitCreateModal($event)"/>
    </template>
  </button>
</template>

<script>
import BeekmanFetchDynamicCreateModal from '@ui/Components/Modals/FetchDynamicCreateModal.vue';

export default {
  components: {
    BeekmanFetchDynamicCreateModal,
  },
  emits: ['submit', 'selectApplications', 'close'],
  props: {
    type: {
      type: String,
      default: 'button',
    },
    element: {
      type: String,
      default: 'button',
    },
    styling: {
      type: [Object, String],
      default: 'primary',
    },
    tooltip: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedApplicationId: {
      default: null,
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.button.focus();
    }
  },
  data() {
    return {
      openDynamicModal: false,
    };
  },
  methods: {
    canOpen() {
      if (window.modals.list.indexOf(this.url) === -1) {
        this.openDynamicModal = true;
      } else {
        this.$flashError('Pagina is al geopend');
      }
    },
    submitCreateModal(props) {
      this.openDynamicModal = false;
      this.$emit('submit', props);
    },
    closeModal() {
      this.openDynamicModal = false;
      this.$emit('close');
    },
  }
}
</script>
