<template>
  <button ref="button" :title="title" :tabindex="tabindex" :class="[element, styling, 'disabled:cursor-not-allowed']" :type="type" @click="canOpen" :disabled="disabled">
    <slot></slot>
    <template v-if="openDynamicModal">
      <beekman-fetch-dynamic-modal v-if="modalType === 'dynamic'" :url="url ? url : $route(routeUrl, routeParams)" @loading="loading" @close="closeModal" :tab-has-lock="tabHasLock"/>
      <beekman-fetch-dynamic-edit-modal v-if="modalType === 'edit'" :url="url ? url : $route(routeUrl, routeParams)" @loading="loading" @close="closeModal"/>
    </template>
  </button>
  <template v-if="openDynamicModal">
    <beekman-fetch-overlay-modal v-if="modalType === 'overlay'" :url="url ? url : $route(routeUrl, routeParams)" @loading="loading" @close="closeModal"/>
  </template>
</template>

<script>
import BeekmanFetchDynamicModal from '@ui/Components/Modals/FetchDynamicModal.vue';
import BeekmanFetchDynamicEditModal from '@ui/Components/Modals/FetchDynamicEditModal.vue';
import BeekmanFetchOverlayModal from '@ui/Components/Modals/FetchOverlayModal.vue';

export default {
  components: {
    BeekmanFetchDynamicModal,
    BeekmanFetchDynamicEditModal,
    BeekmanFetchOverlayModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
    modalType: {
      type: String,
      default: 'dynamic',
    },
    element: {
      type: String,
      default: 'button',
    },
    styling: {
      type: [Object, String],
      default: 'primary',
    },
    tooltip: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
    routeUrl: {
      type: String,
      default: '',
    },
    routeParams: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabHasLock: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: [String, Number],
      default: -1,
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.button.focus();
    }
  },
  emits: ['click', 'close', 'loading'],
  data() {
    return {
      openDynamicModal: false,
    };
  },
  methods: {
    canOpen() {
      if (this.disabled) {
        return;
      }
      if (this.openDynamicModal) {
        this.openDynamicModal = false;
      } else if (window.modals.list.indexOf(this.routeUrl) === -1) {
        this.openDynamicModal = true;
        this.$emit('click');
      } else {
        this.$flashError('Pagina is al geopend');
      }
    },
    closeModal() {
      this.openDynamicModal = false;
      this.$emit('close');
    },
    loading(loading) {
      this.$emit('loading', loading);
    },
  }
}
</script>
