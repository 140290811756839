<template>
  <button type="button"
          class="inline-flex items-center px-3 py-2 text-themeText w-full justify-between
                 text-sm leading-4 focus:outline-none hover:text-opacity-75
                 transition ease-in-out duration-150">

      <div class="text-left" v-if="!reversedDisguise || !$user.disguised">
        <div class="font-medium text-sm"><span v-if="$user.color" class="user-color w-3 h-3 rounded-full float-left mt-1 border border-white mr-1" :style="{'background-color':$user.color}"></span>{{ $user.name }}</div>
        <div class="font-medium text-xs opacity-50">{{ $user.email }}</div>
      </div>

      <div class="text-left" v-if="reversedDisguise && $user.disguised">
        <div class="font-medium text-sm"><span v-if="$user.disguised_from.color" class="user-color w-3 h-3 rounded-full float-left mt-1 border border-white mr-1" :style="{'background-color':$user.disguised_from.color}"></span>{{ $user.disguised_from.name }}</div>
        <div class="font-medium text-xs opacity-50">{{ $user.disguised_from.email }}</div>
      </div>

      <i class="ml-2 -mr-0.5 fal fa-angle-down"></i>
  </button>
</template>
<script>
export default {
  props: {
    reversedDisguise: {
      type: Boolean,
      default: false
    },
  },
}
</script>