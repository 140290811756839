<template>
  <button ref="button" :class="[element, styling, isActive(), 'disabled:cursor-not-allowed']"
          :type="type"
          :title="title"
          @click="$emit('click')">
      <beekman-badge-count v-if="badgeCount > 0" :badge-count="badgeCount"/>
    <span v-if="title.length" class="sr-only">{{title}}</span>
    <slot></slot>
  </button>
</template>

<script>
import BeekmanBadgeCount from '@ui/Components/Buttons/BadgeCount.vue';

export default {
  components: {
    BeekmanBadgeCount,
  },
  emits: ['click'],
  props: {
    type: {
      type: String,
      default: 'button',
    },
    title: {
      type: String,
      default: '',
    },
    element: {
      type: String,
      default: 'button',
    },
    styling: {
      type: [String, Object],
      default: 'primary',
    },
    tooltip: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    badgeCount: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.button.focus();
    }
  },
  methods: {
    isActive() {
      return this.active ? 'active' : '';
    }
  }
}
</script>
