<template>
  <modal :show="show && dataLoaded" :width="90" :height="70" @close="close">
    <modal-header>
      {{data.cannedMessageType.description}}
    </modal-header>
    <div class="modal-content">
      <div class="flex flex-wrap">
        <ul class="w-1/6 overflow-scroll pr-4" style="max-height: 60vh;">
          <li v-for="cannedMessageGroup in cannedMessageGroups">
            <button class="w-full text-left rounded py-1 px-2" :class="{'bg-theme text-white':activeCannedMessageGroupId == cannedMessageGroup.id}" @click="filterCannedMessageGroup(cannedMessageGroup.id)">
              {{cannedMessageGroup.name}}
            </button>
          </li>
        </ul>
        <ul class="w-2/6 overflow-scroll pr-4" style="height: 60vh;">
          <li class="mb-1"><input v-model="searchTerm" ref="search" type="text" :placeholder="$trans('global.search')" @input="searching"/></li>
          <li v-for="cannedMessage in cannedMessages">
            <button class="w-full text-left rounded py-1 px-2" :class="{'bg-theme text-white':activeCannedMessage.id == cannedMessage.id}" @click="setCannedMessage(cannedMessage)">
              {{cannedMessage.title}} <i v-if="cannedMessage.is_html" class="fal fa-code float-right bg-gray-300 text-black px-1 py-0.5 rounded" title="HTML"></i>
            </button>
          </li>
        </ul>
        <div class="w-1/2 p-2 bg-gray-300 text-black rounded" v-html="activeCannedMessage.body"></div>
      </div>
    </div>
    <modal-footer ref="footerButtons">
      <template #footerButtons>
        <beekman-button styling="secondary mr-2" @click="close">
          {{ __('global.close') }}
        </beekman-button>
        <beekman-button styling="primary" :disabled="!activeCannedMessage.body" @click="addBody">
          {{ __('global.add') }}
        </beekman-button>
      </template>
    </modal-footer>
  </modal>
</template>
<script>
import Modal from '@ui/Components/Modals/Modal.vue';
import ModalHeader from '@ui/Components/Modals/HeaderNoButtons.vue';
import ModalFooter from "@ui/Components/Modals/Footer.vue";
import BeekmanButton from "@ui/Components/Button.vue";

export default {
  components: {
    BeekmanButton,
    ModalFooter,
    Modal,
    ModalHeader,
  },
  emits: ['close', 'addBody'],
  props: {
    type: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    applicationId: {
      type: Number,
    },
  },
  data() {
    return {
      data: null,
      cannedMessages: null,
      searchedMessages: null,
      cannedMessageGroups: null,
      activeCannedMessageGroupId: null,
      activeCannedMessage: {id: null, body: null},
      dataLoaded: false,
      searchTerm: '',
    };
  },
  created() {
    this.dataLoaded = false;
    axios.get(this.$route('system-management.canned-messages.data', {type:this.type, applicationId: this.applicationId})).then((response) => {
      this.data = response.data;
      this.cannedMessageGroups = this.data.cannedMessageGroups;
      this.cannedMessages = this.data.cannedMessages;
      this.dataLoaded = true;
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    addBody() {
      if (this.activeCannedMessage.body) {
        axios.post(this.$route('system-management.canned-messages.used', {id :this.activeCannedMessage.id})).then((response) => {
          this.$emit('addBody', this.activeCannedMessage.body);
          this.$emit('close');
        });
      }
    },
    filterCannedMessageGroup(cannedMessageGroupId) {
      this.searchTerm = '';
      if (cannedMessageGroupId === this.activeCannedMessageGroupId) {
        cannedMessageGroupId = null;
      }
      this.activeCannedMessageGroupId = cannedMessageGroupId;
      if (cannedMessageGroupId == null) {
        this.cannedMessages = this.data.cannedMessages;
      } else {
        this.filteredCannedMessages(cannedMessageGroupId);
      }
    },
    setCannedMessage(cannedMessage) {
      this.activeCannedMessage.id = cannedMessage.id;
      this.activeCannedMessage.body = cannedMessage.body;
    },
    filteredCannedMessages(cannedMessageGroupId) {
      this.resetActiveCannedMessage();
      let filteredCannedMessages = [];
      this.data.cannedMessages.forEach(function (cannedMessage) {
        if (cannedMessage.canned_message_group_id == cannedMessageGroupId) {
          filteredCannedMessages.push(cannedMessage);
        }
      });
      this.cannedMessages = filteredCannedMessages;
    },
    resetActiveCannedMessage() {
      this.activeCannedMessage = {
        id: null,
        body: null
      };
    },
    searching() {
      this.search();
    },
    search: window._.debounce(function () {
      this.resetActiveCannedMessage();
      if (this.activeCannedMessageGroupId) {
        this.filteredCannedMessages(this.activeCannedMessageGroupId);
      } else {
        this.cannedMessages = this.data.cannedMessages;
      }
      if (this.searchTerm.length > 2) {
        let foundCannedMessages = [];
        let searchTerm = this.searchTerm.toLowerCase();
        this.cannedMessages.forEach((cannedMessage) => {
          if (cannedMessage.title.toLowerCase().includes(searchTerm) || cannedMessage.body.toLowerCase().includes(searchTerm)) {
            foundCannedMessages.push(cannedMessage);
          }
        });
        this.cannedMessages = foundCannedMessages;
      }
    }, 300),
  }
}
</script>
