import EventBus from '@ui/eventBus.js';

export default {
    install(app, options) {
        // default plugin setting
        let settings = {
            previousCode: '',
            barcode: '',
            minChars: 4,
            targetId: '',
            scannerSensitivity: 20,
            // Used to check for specific input field
            // Please note that if "specificInputField" set to "true" you need to append some input field with "data-barcode" and then only this input will be read
            specificInputField: false,
            // Some scanners do not end their sequence with the ENTER key.
            // This option allows "finishing" the sequence without an ENTER key
            // after the number of ms defined in `setting.scannerSensitivity`
            // elapses after the last character in the sequence.
            // Example:
            // (without timeout, sequence ends with ENTER):
            //   1. Scan barcode
            //   2. Scanner sends sequence of characters to device, ending with ENTER (13) key
            //   3. `callback` passed in `init()` is called
            // (without timeout, sequence ends without ENTER):
            //   1. Scan barcode
            //   2. Scanner sends sequence of characters to device. Final character is not ENTER
            //   3. `callback` is not called until the ENTER key is pressed
            // (with timeout, sequence ends without ENTER):
            //   1. Scan barcode
            //   2. Scanner sends sequence of characters to device. Final character is not ENTER
            //   3. After `setting.scannerSensitivity` ms elapses, `callback` is called
            callbackAfterTimeout: false,
            callback: null,
            hasListener: false,
            pressedTime: [],
            lastTimeElapsed: 0,
            // This is used for scanners which do not send
            // ENTER (13) as the final key code
            // in a barcode sequence.
            timeout: null,
            // Used to emit messages
            eventBus: null,
            // Used for determing whether or not to emit a `start` event
            isProcessing: false,

        }

        // initial plugin setting
        if (options) {
            settings.specificInputField = options.specificInputField || false;
            settings.scannerSensitivity = options.sensitivity || settings.scannerSensitivity;
            settings.callbackAfterTimeout = options.callbackAfterTimeout || false;
        }

        app.config.globalProperties.$barcodeScanner = {};

        app.config.globalProperties.$barcodeScanner.init = (callback, options = {}) => {
            // add listenter for scanner
            // use keypress to separate lower/upper case character from scanner
            addListener('keypress');
            // use keydown only to detect Tab event (Tab cannot be detected using keypress)
            addListener('keydown');
            settings.callback = callback;

            // use specific input field with "data-barcode" as attribute
            if (options.specificInputField) {
                settings.specificInputField = options.specificInputField;
            }

            // allow an event bus to be passed back to the caller
            if (options.eventBus) {
                settings.eventBus = EventBus;
                return settings.eventBus;
            }
        }

        app.config.globalProperties.$barcodeScanner.destroy = () => {
            // remove listener
            removeListener('keypress');
            removeListener('keydown');
        }

        app.config.globalProperties.$barcodeScanner.lastTimeElapsed = () => {
            return settings.lastTimeElapsed;
        }

        app.config.globalProperties.$barcodeScanner.hasListener = () => {
            return settings.hasListener;
        }

        app.config.globalProperties.$barcodeScanner.getPreviousCode = () => {
            return settings.previousCode;
        }

        app.config.globalProperties.$barcodeScanner.setSensitivity = (sensitivity) => {
            settings.scannerSensitivity = sensitivity;
        }

        function addListener(type) {
            if (settings.hasListener) {
                removeListener(type);
            }
            window.addEventListener(type, onInputScanned);
            settings.hasListener = true;
        }

        function removeListener(type) {
            if (settings.hasListener) {
                window.removeEventListener(type, onInputScanned);
                settings.hasListener = false;
            }
        }

        // this is called when either an ENTER key (13) is received
        // or when the `settings.timeout` fires, following
        // a scan sequence
        function finishScanSequence() {
            // clear and null the timeout
            if (settings.timeout) {
                clearTimeout(settings.timeout);
            }
            settings.timeout = null;

            // scanner is done and trigger Enter/Tab then clear barcode and play the sound if it's set as true
            settings.callback({
                'barcode': settings.barcode,
                'target': settings.targetId,
            });
            // backup the barcode
            settings.previousCode = settings.barcode;
            // clear textbox
            settings.barcode = '';
            // clear pressedTime
            settings.pressedTime = [];
            emitEvent("finish");
            settings.isProcessing = false;
        }

        function emitEvent(type, payload) {
            if (settings.eventBus) {
                settings.eventBus.$emit(type, payload);
            }
        }

        function onInputScanned(event) {
            // scan and validate each character
            if (event.type === 'keypress') {
                settings.barcode += event.key;
            }

            if (checkIsScanner(Date.now())) {
                // ignore fields that don't have the propper data attributes
                if (!event.target.hasAttribute('data-barcode') && !event.target.hasAttribute('check-barcode')) {
                    event.target.value = '';
                    return;
                }

                if (!settings.isProcessing) {
                    emitEvent("start", event);
                    settings.isProcessing = true;
                }
                // check if field has 'data-barcode' attribute
                let barcodeIdentifier = false;

                settings.targetId = event.target.id;

                if (settings.specificInputField) {
                    barcodeIdentifier = event.target.hasAttribute('data-barcode');
                } else {
                    barcodeIdentifier = true;
                }
                if (barcodeIdentifier && (event.keyCode === 13 || event.keyCode === 9) && settings.barcode !== '') {
                    finishScanSequence();

                    // prevent navigation for scanner
                    if (event.keyCode === 9 || event.keyCode === 13) {
                        event.preventDefault();
                    }
                } else {
                    // reset the finish sequence timer and add the key to the buffer
                    if (settings.timeout) {
                        clearTimeout(settings.timeout);
                    }

                    settings.timeout = settings.callbackAfterTimeout
                        && settings.barcode.length > 0
                        && setTimeout(finishScanSequence, settings.scannerSensitivity);
                }
            }
        }

        // check whether the keystrokes are considered as scanner or human
        function checkIsScanner(timestamp) {
            // push current timestamp to the register
            settings.pressedTime.push(timestamp);
            // when register is full (ready to compare)
            if (settings.pressedTime.length === settings.minChars) {
                // compute elapsed time between 2 keystrokes
                let timeElapsed = settings.pressedTime[(settings.minChars - 1)] - settings.pressedTime[0];
                settings.lastTimeElapsed = timeElapsed;
                // fast enough (assume as scanner)
                if (timeElapsed <= settings.scannerSensitivity) {
                    // reset pressed time
                    settings.pressedTime = [];
                    return true;
                }
                // reset pressed time
                settings.pressedTime = [];
                // reset barcode
                settings.barcode = '';
            }
            // no scanner
            return false;
        }
    }
}
