<template>
    <inertia-link :href="href ? (route().has(href) ? route(href) : `/${href}`) : '/'"
                  :class="[classes()]"
                  :headers="$inertiaHeaders"
                  :only="only">
        <slot></slot>
    </inertia-link>
</template>

<script>
    export default {
        props: {
          href: String,
          only: Array,
        },
        methods: {
          link() {
            return this.href.length ? `/${this.href}` : '/';
          },
          active() {
            const link = this.href.length ? `/${this.href}` : '/';
            return this.$page.url.startsWith(link);
          },
          classes() {
            return this.active() ? 'active' : '';
          }
        }
    }
</script>
