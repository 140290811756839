<template>
  <modal :closeable="closeable" :show="show" :width="width" @close="close">
    <div class="modal-content rounded-t">
      <div class="flex items-start">
        <div class="rounded-full bg-red-100 px-2 py-1">
          <i class="fal fa-triangle-exclamation text-red-500 text-lg"/>
        </div>
        <div class="ml-4 flex-1">
          <h3 class="text-lg">
            <slot name="title"></slot>
          </h3>
          <div class="mt-2">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
    <modal-footer ref="footerButtons">
      <template #footer>
        <slot name="footer"></slot>
      </template>
      <template #footerButtons>
        <slot name="footerButtons"></slot>
      </template>
    </modal-footer>
  </modal>
</template>

<script>
import Modal from '@ui/Components/Modals/Modal.vue';
import ModalFooter from '@ui/Components/Modals/Footer.vue';

export default {
  components: {
    Modal,
    ModalFooter,
  },
  emits: ['close'],
  props: {
    show: {
      default: false
    },
    width: {
      default: '30'
    },
    closeable: {
      default: true
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
}
</script>
