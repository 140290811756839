import Hashids from "hashids";

export default {
    install(app, options) {

        function isNumeric(value) {
            if (typeof value === "number") {
                return true;
            }
            return !isNaN(value);
        }

        // hash id's
        app.config.globalProperties.$getHashedId = function (id) {
            if (options.isLocal) {
                return id;
            }
            const hashids = new Hashids(options.salt, 20, 'abcdefghijklmnopqrstuvwxyz1234567890-');
            return hashids.encode(id);
        }

        // route with hashed id's
        app.config.globalProperties.$route = function (name, parameters, absolute = true, config) {
            if (typeof parameters === 'object') {
                for (const [parameter, value] of Object.entries(parameters)) {
                    if ((parameter.endsWith('_id') || parameter === 'id') && isNumeric(value)) {
                        parameters[parameter] = app.config.globalProperties.$getHashedId(value);
                    }
                }
            }
          try {
            return route(name, parameters, absolute, config)
          } catch (error) {
            console.error(error, name);
          }
        }
    }
}