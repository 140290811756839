import {mask} from "vue-the-mask";

export default {
    directives: {
        mask: (el, binding) => {
            if (!binding.value) return;
            mask(el, binding);
        }
    },
    methods: {
        unmaskForm(form) {
            if (Object.keys(this.form.masks).length) {
                for (let [key, mask] of Object.entries(this.form.masks)) {
                    form[key] = this.unmask(form[key], mask);
                }
            }
            return form;
        },
        unmask(maskedValue, mask) {
            if (mask == '' || maskedValue.length !== mask.length) {
                return maskedValue;
            }
            let defaultTokens = ['#', 'X', 'S', 'A', 'a', '!'];
            let unmaskedValue = '';
            let isToken;
            for (let i = 0; i < maskedValue.length; i++) {
                isToken = false;
                for (let j = 0; j < defaultTokens.length; j++) {
                    if (mask[i] == defaultTokens[j]) {
                        isToken = true;
                    }
                }
                if (isToken) {
                    unmaskedValue += maskedValue[i];
                }
            }

            return unmaskedValue;
        },
    },
}
