import BeekmanDropdown from '@ui/Components/Dropdown.vue';

export default {
    components: {
        BeekmanDropdown,
    },
    data() {
        return {
            currentOption: 0,
        };
    },
    methods: {
        onDown(event) {
            event.preventDefault();
            if (this.currentOption < Object.keys(this.filtered).length) {
                this.currentOption++;
                this.fixScrolling();
            }
        },
        onUp(event) {
            event.preventDefault();
            if (this.currentOption > 0) {
                this.currentOption--;
                this.fixScrolling();
            }
        },
        fixScrolling() {
            if (this.$refs[`row-${this.currentOption}`] && this.$refs[`row-${this.currentOption}`][0]) {
                let {height} = this.$refs[`row-${this.currentOption}`][0].getBoundingClientRect();
                this.$refs.dropdown.$refs['dropdownList'].scrollTop = height * this.currentOption;
            }
        },
    },
}
