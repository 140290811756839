import SelectDropdown from "@ui/Mixins/Selects/Dropdown.js";
import SelectSearchable from "@ui/Mixins/Selects/Searchable.js";

export default {
    emits: ['selected', 'blur'],
    mixins: [
        SelectDropdown,
        SelectSearchable,
    ],
    props: {
        width: {
            default: 32,
        },
        classes: {},
        addValueWhenNoResult: {
            default: false,
        },
        disabled: {
            default: false,
        },
        resetButton: {
            default: true,
        },
        tabindex: {
            default: null,
        },
        resetSelect: {
            default: false,
        },
    },
    methods: {
        focus() {
            if (!this.disabled) {
                this.forceOpen = true;
                this.forceClose = false;
            }
        },
        blur() {
            this.forceOpen = false;
            this.forceClose = true;
            this.$emit('blur');
        },
    },
}
