<template>
  <div>
    <div :id="id" :tabindex="index" @focus="focus" :class="initClass" :style="'height:'+config.height+'px;'">
      <editor
          :id="editorId"
          v-model="proxyInput"
          tinymceScriptSrc="/js/tinymce/tinymce.min.js"
          :disabled="(disabled || config.disabled) && !showCannedMessages"
          :init="{
            promotion: false,
            skin: skin,
            content_css: contentCSS,
            height: config.height,
            menubar: config.menubar,
            branding: false,
            resize: false,
            statusbar: config.statusbar,
            plugins: config.plugins,
            toolbar: config.toolbar,
            quickbars_selection_toolbar: config.quickbars_selection_toolbar,
            noneditable_editable_class: 'is-editable',
            noneditable_noneditable_class: 'is-locked',
            placeholder: text,
            init_instance_callback: (editor) => {
              editor.on('blur', (e) => {
                this.focusNextField(e, this.index);
              });
            },
            setup: (editor) => {
              this.editor = editor;
              if (config.withCannedMessages) {
                editor.ui.registry.addButton('cannedMessages', {
                  icon: 'comment',
                  onAction: function () {
                    this.showCannedMessages = true;
                  }.bind(this)
                });
              }
              if (config.withCustomLinks) {
                editor.ui.registry.addButton('customLinks', {
                  icon: 'link',
                  onAction: function () {
                    let element = editor.selection.getNode();
                    let text = editor.selection.getContent();
                    let title = '', href = '', target = '', classes = '';
                    let parent = element.parentNode;
                    if (element.nodeName == 'A') {
                        text = element.innerText;
                        title = editor.dom.getAttrib(element, 'title');
                        href = editor.dom.getAttrib(element, 'href');
                        target = editor.dom.getAttrib(element, 'target');
                        target = editor.dom.getAttrib(element, 'target');
                        classes = editor.dom.getAttrib(element, 'class');
                    } else if(parent.nodeName == 'A') {
                        text = parent.innerHTML;
                        title = editor.dom.getAttrib(parent, 'title');
                        href = editor.dom.getAttrib(parent, 'href');
                        target = editor.dom.getAttrib(parent, 'target');
                        classes = editor.dom.getAttrib(parent, 'class');
                    }
                    this.customLinkData = {
                        text: text,
                        title: title,
                        url: href,
                        target: target,
                        classes: classes,
                    };
                    this.showCustomLinks = true;
                  }.bind(this)
                });
              }
            }
          }"

          :initial-value="modelValue"
          @init="init"
      />
      <template v-if="config.withCannedMessages && showCannedMessages">
        <beekman-canned-messages-modal :show="showCannedMessages"
                                       :type="config.cannedMessageType"
                                       :applicationId="data.application_id"
                                       @close="showCannedMessages = false"
                                       @addBody="addCannedMessage($event)" />
      </template>
      <template v-if="config.withCustomLinks && showCustomLinks">
        <beekman-custom-links-modal :show="showCustomLinks"
                                    :applicationId="data.application_id"
                                    :languageId="data.language_id"
                                    :data="customLinkData"
                                    @close="showCustomLinks = false"
                                    @addLink="addCustomLink($event)" />
      </template>
    </div>
  </div>
</template>
<style>
.tox-notifications-container {display: none !important;}
</style>
<script>
import Editor from '@tinymce/tinymce-vue';
import BeekmanFocusField from "@ui/Mixins/FocusField.js";
import { mapState } from 'pinia';
import { userStore } from '@ui/Stores/User.js';
import BeekmanCannedMessagesModal from "@ui/Components/Modals/CannedMessages.vue";
import BeekmanCustomLinksModal from "@ui/Components/Modals/CustomLinks.vue";
import BeekmanFocusNextField from "@crud/Mixins/Form/FocusNextField";

export default {
  components: {
    BeekmanCannedMessagesModal,
    BeekmanCustomLinksModal,
    'editor': Editor
  },
  emits: ['update:modelValue'],
  props: {
    id: String,
    modelValue: String,
    isValid: [Boolean, Number],
    config: [Array, Object],
    reference: String,
    type: String,
    disabled: [Boolean, Number],
    index: [Number, String],
    data: [Array, Object],
    text: [String],
  },
  mixins: [
    BeekmanFocusField,
    BeekmanFocusNextField
  ],
  data() {
    return {
      editorId: 'tinymceEditor'+this.id+this.index+(this.data.id ?? 'create'),
      initClass: 'tinymce-initializing',
      skin: 'oxide',
      contentCSS: '/css/editor.css',
      showCannedMessages: false,
      showCustomLinks: false,
      customLinkData: {},
      editor: null,
    }
  },
  created() {
    if (!this.config.forceLightMode && this.darkMode) {
      this.skin = 'tinymce-5-dark',
          this.contentCSS = '/css/editor-dark.css';
    }
  },
  computed: {
    ...mapState(userStore, ['darkMode']),
    proxyInput: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    init() {
      this.initClass = '';
    },
    addCannedMessage(text) {
      this.editor.insertContent(text);
    },
    addCustomLink(data) {
      let element = this.editor.selection.getNode();
      if (element.nodeName != 'A') {
        this.editor.selection.setNode(this.editor.dom.create('a', data, data.text));
      } else {
        this.editor.dom.setAttribs(element, data);
      }
    },
    focus() {
      this.editor.execCommand('mceFocus');
      let marker = this.editor.dom.select('#cursor_marker');
      if (marker.length) {
        marker[0].removeAttribute('id');
        let range = new Range(), pos = 0;
        range.setStart(marker[0], pos);
        range.setEnd(marker[0], pos);
        this.editor.selection.setRng(range);
      }
    },
  }
}
</script>
