<template>
  <modal :show="show && blobImage && dataLoaded" :width="50" @close="close">
    <modal-header>
      {{ data.title }}
    </modal-header>
    <div class="modal-content">
      <template v-for="(fields, columnIndex) in form.elements">
        <beekman-form-field
            v-for="(field, index) in fields"
            :key="index"
            v-model="useForm[field.name]"
            v-model:checked="useForm[field.name]"
            :data="useForm"
            :disabled="false"
            :errors="{}"
            :field="field"
            :form="form"
            :index="index"
        />
      </template>
      <img src="" id="direct-upload-example" class="max-w-full" />
    </div>
    <modal-footer ref="footerButtons">
      <template #footerButtons>
        <beekman-button styling="secondary mr-2" @click="close">
          {{ __('global.close') }}
        </beekman-button>
        <beekman-button styling="primary" :disabled="uploadButtonDisabled" @click="submit()">
          {{ data.submitText }}
        </beekman-button>
      </template>
    </modal-footer>
  </modal>
</template>
<script>
import Modal from '@ui/Components/Modals/Modal.vue';
import ModalHeader from '@ui/Components/Modals/HeaderNoButtons.vue';
import ModalFooter from "@ui/Components/Modals/Footer.vue";
import BeekmanButton from "@ui/Components/Button.vue";
import BeekmanFormField from "@ui/Components/Forms/Field.vue";

export default {
  components: {
    Modal,
    ModalHeader,
    ModalFooter,
    BeekmanButton,
    BeekmanFormField,
  },
  emits: ['close'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    blobImage: {
      type: Object,
      required: true,
    },
    applicationId: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.dataLoaded = false;
    axios.get(this.$route('system-management.uploads.direct-uploads.data')).then((response) => {
      this.data = response.data;
      this.form = this.data.form;
      this.useForm = this.data.form.form;
      this.useForm.name = 'image';
      this.useForm.description = 'image';
      this.useForm.application_id = this.applicationId;
      this.useForm.image = this.blobImage;
      const reader = new FileReader();
      reader.readAsDataURL(this.blobImage);
      reader.onloadend = function() {
        document.getElementById('direct-upload-example').src = reader.result;
      };
      this.dataLoaded = true;
    });
  },
  data() {
    return {
      dataLoaded: false,
      form: {},
      useForm: {},
      uploadButtonDisabled: true,
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      if (!this.uploadButtonDisabled) {
        let formData = new FormData();
        let data = this.useForm;
        formData.append('_method', 'post');
        for (const [key, value] of Object.entries(data)) {
          formData.append(key, value);
        }
        axios.post(this.$route('system-management.uploads.direct-uploads.store'), formData).then(response => {
          this.close();
        });
      }
    },
  },
  watch: {
    useForm: {
      deep: true,
      handler() {
        this.uploadButtonDisabled = (this.useForm.name === '' || this.useForm.description === '');
      }
    }
  },
}
</script>
