<template>
  <div class="bg-white border border-b-0 rounded shadow-xl"
       role="alert"
       :class="{
          'border-red-200': message.type === 'error',
          'border-green-200': message.type === 'success'
        }"
       style="min-width: 300px"
       @mouseover="pauze = true"
       @mouseleave="pauze = false">
    <div class="flex items-center justify-between p-4">
      <i class="text-xl" :class="{
        'text-red-500 fal fa-bell-exclamation': message.type === 'error',
        'text-green-500 fal fa-check-circle': message.type === 'success'
      }">
      </i>
      <div class="ms-3 text-sm font-bold flex justify-center"
           :class="{
                  'text-red-900': message.type === 'error',
                  'text-green-900': message.type === 'success'
                }"
           v-html="message.text">
      </div>
      <i v-if="canClose" class="p-1.5 text-md fa fa-times text-gray-900 hover:text-gray-600 cursor-pointer"
         @click="destroy">
      </i>
    </div>
    <div class="h-1 rounded-bl"
         :class="{
            'bg-red-700': message.type === 'error',
            'bg-green-700': message.type === 'success',
          }"
         :style="{'width': `${percentLeft}%`}">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    canClose: {
      type: Boolean,
      default: true,
    }
  },
  created() {
    if (this.message.seconds) {
      this.countDown(this.message.seconds);
    }
  },
  data() {
    return {
      timeLeft: 0,
      timeout: null,
      pauze: false,
    }
  },
  computed: {
    percentLeft() {
      return Math.round((this.timeLeft / (this.message.seconds) * 100));
    }
  },
  methods: {
    countDown(left) {
      this.timeLeft = left;
      if(left > 0) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (!this.pauze) {
            left = left -1;
          }
          this.countDown(left);
        }, 1000)
      } else {
        this.destroy();
      }
    },
    destroy() {
      clearTimeout(this.timeout);
      this.$flashDelete(this.message.id);
    },
  },
};
</script>
