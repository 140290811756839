<template>
  <div class="p-2">
    <div class="grid gap-2 grid-cols-3 text-center">
      <beekman-button class="p-2 hover:bg-gray-100 hover:text-theme rounded cursor-pointer" :class="{'active': theme === 'light'}"
                      @click="setTheme('light')" :element="''" :title="'Gebruik light thema'">
        <i class="icon fa-lg fa-solid fa-sun-bright"></i>
      </beekman-button>
      <beekman-button class="p-2 hover:bg-gray-100 hover:text-theme rounded cursor-pointer" :class="{'active': theme === 'dark'}"
                      @click="setTheme('dark')" :element="''" :title="'Gebruik donker thema'">
        <i class="icon fa-lg fa-solid fa-moon"></i>
      </beekman-button>
      <beekman-button class="p-2 hover:bg-gray-100 hover:text-theme rounded cursor-pointer" :class="{'active': theme === 'system'}"
                      @click="setTheme('system')" :element="''" :title="'Gebruik theme ingesteld in windows'">
        <i class="icon fa-lg fa-solid fa-desktop"></i>
      </beekman-button>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'pinia';
  import { userStore } from '@ui/Stores/User.js';
  import BeekmanButton from "@ui/Components/Button.vue";
  export default {
    components: {
      BeekmanButton,
    },
    data() {
      return {
        visible: false,
        theme: '',
      };
    },
    created() {
      const userPreference = this.$getUserPreference('theme');

      if (userPreference) {
        this.theme = userPreference;
      } else {
        // use system value as default
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          this.theme = 'dark';
        } else {
          this.theme = 'light';
        }
      }

      this.storeTheme(this.theme);
    },
    methods: {
      ...mapActions(userStore, {
        storeTheme: 'setTheme'
      }),
      setTheme(theme) {
        this.$addUserPreference('theme', theme).then(() => {
          this.theme = theme;
          this.storeTheme(this.theme);
        });
      },
      handleKeydown(event) {
        // ALT + numeric
        if (event.altKey && event.keyCode === 84) {
          if (this.theme === 'light') {
            this.setTheme('dark');
          }
          if (this.theme === 'dark') {
            this.setTheme('light');
          }
        }
      },
    },
    beforeMount() {
      window.addEventListener('keydown', this.handleKeydown, null);
    },
    beforeUnmount() {
      window.removeEventListener('keydown', this.handleKeydown);
    }
  }
</script>
