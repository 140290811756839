import dragMove from './dragMove';

import eventListener from '../utils/eventListener';
import moveElementTransform from '../utils/moveElementTransform';
import returnPositionString from '../utils/returnPositionString';
import updateMousePosition from '../utils/updateMousePosition';

export default function () {
  // Prevent TypeError from showing
  if (!(window.data.grab && window.data.move)) return;

  // Stop move animation
  cancelAnimationFrame(window.data.posAnimation);

  // Remove setUpMovement() if mouse/touch hasn't moved
  eventListener(['mousemove', 'touchmove'], dragMove, 'remove');
  // Modal drag fixes outside window
  let elementPositions = window.data.grab.getBoundingClientRect();
  let positionTop = Math.round(elementPositions.top);
  let positionLeft = Math.round(elementPositions.left);
  let topMaximum = window.innerHeight - elementPositions.height;
  let leftMaximum = window.innerWidth - 150;
  // Top
  if (positionTop < 0) {
    window.data.relativeY -= positionTop;
    window.data.mouseY -= positionTop;
  }
  // Bottom
  if (positionTop > topMaximum) {
    let setBottom = positionTop - topMaximum;
    window.data.relativeY -= setBottom;
    window.data.mouseY -= setBottom;
  }
  // Left
  if (positionLeft < 0) {
    window.data.relativeX -= positionLeft;
    window.data.mouseX -= positionLeft;
  }
  // Right
  if (positionLeft > leftMaximum) {
    let setLeft = positionLeft - leftMaximum;
    window.data.relativeX -= setLeft;
    window.data.mouseX -= setLeft;
  }

  // Replace transform properties with left and top
  moveElementTransform(
      window.data.matrix ? returnPositionString(window.data.matrix, 0, 0) : 'none',
      `${window.data.matrixX + window.data.relativeX}px`,
      `${window.data.matrixY + window.data.relativeY}px`,
  );

  // Remove CSS classes
  window.data.grab.classList.remove(window.data.class.down);
  window.data.move.classList.remove(window.data.class.move);

  // Stop updating mouse position
  eventListener(['mousemove', 'touchmove'], updateMousePosition, 'remove');
}
