<template>
  <template v-if="field.component == 'Spacing'">
    <div class="element-row">
      <span class="w-full field-spacing"></span>
    </div>
  </template>
  <template v-if="field.component == 'Line'">
    <div class="element-row">
      <hr class="w-full field-line" />
    </div>
  </template>
  <template v-if="field.component == 'Title'">
    <div class="element-row">
      <span class="field-title">{{field.text}}</span>
    </div>
  </template>
  <template v-if="!onlyComponent && field.component != 'Hidden' && !this.excludeComponents.includes(field.component)">
    <div :class="[`element-${field.component}`, field.props.fullWidth ? 'full-width' : 'element-row justify-between', field.props.hiddenByDisabledIf && disabledIf() ? 'hidden' : '']">
      <template v-if="!checkboxList">
        <div :class="field.props.fullWidth ? 'w-full mb-1' : [labelWidth || 'w-1/3', 'pr-2']">
          <beekman-label :name="field.name"
                         :explanation="field.explanation"
                         :props="field.props"
                         :data="data"
                         :required="field.required"
                         :validation="form.validations[field.name]"
                         :value="field.text"/>
        </div>
        <div :class="[elementWidth, field.props.fullWidth ? 'w-full' : 'pl-2 flex']" class="element-container">
          <div :class="fieldClasses()">
            <component
                :is="formElement(field.component, field.custom)"
                :id="field.name"
                :ref="field.name"
                v-model="data[field.name]"
                v-model:checked="data[field.name]"
                :config="field.props"
                :data="data"
                :disabled="disabled || disabledOnEdit() || disabledIf()"
                :focusElement="field.name == form.focusElement"
                :setFocusElement="setFocusElement"
                :index="index"
                :is-valid="hasErrors(field.name).length === 0"
                :mask="field.mask"
                @error="fieldHasError"
                @selected="selected"
                @hasFile="$emit('hasFile', $event)"
                @uploading="$emit('uploading', $event)"
                @importedData="$emit('importedData', $event)"
                @updateDirty="$emit('updateDirty', $event)"
                @update:modelValue="updateModalValue"
                @copyValue="copyValue"/>
            <template v-if="field.props.imagePreview && imagePreview && data[field.name]">
              <img :src="imagePreview"
                   id="imagePreview"
                   class="cursor-zoom-in max-h-12 mt-1"
                   @click="showLightBox = !showLightBox"
              />
              <beekman-lightbox v-if="showLightBox" :media="[{src:imagePreview}]" @onClosed="closeLightBox"/>
            </template>
          </div>
          <template v-for="button in field.props.buttons">
            <beekman-buttons :button="button" :styling="setButtonStyling(button.classes)" :only-icon="true" :buttons-disabled="!disabled" />
          </template>
          <beekman-edit-modal-button v-if="data[`${field.name}-editRoute`]" :url="data[`${field.name}-editRoute`]" :styling="field.component == 'Address' ? 'secondary ml-1 h-6' : 'secondary ml-1'" :disabled="!disabled">
            <i class="fal fa-eye w-5"></i>
          </beekman-edit-modal-button>
          <template v-for="button in field.props.defaultButtons">
            <beekman-create-modal-button v-if="disabled && data[field.name] == '' && button.route && button.type == 'create'" :url="button.route" styling="secondary ml-1" :disabled="!disabled">
              <i class="w-5" :class="button.icon"></i>
            </beekman-create-modal-button>
            <beekman-edit-modal-button v-if="button.route && button.type == 'edit'" :url="button.route" :styling="field.component == 'Address' ? 'secondary ml-1 h-6' : 'secondary ml-1'" :disabled="!disabled && !button.openWhenLocked">
              <i class="w-5" :class="[button.openWhenLocked && !disabled ? 'fal fa-pencil' : button.icon]"></i>
            </beekman-edit-modal-button>
            <beekman-dynamic-modal-button v-if="button.route && button.type == 'dynamic'" :url="button.route" :styling="field.component == 'Address' ? 'secondary ml-1 h-6' : 'secondary ml-1'" :disabled="!disabled && !button.openWhenLocked">
              <i class="w-5" :class="[button.openWhenLocked && !disabled ? 'fal fa-pencil' : button.icon]"></i>
            </beekman-dynamic-modal-button>
          </template>
          <div v-if="field.afterText" class="pt-1 ml-2" :title="field.afterText">{{ field.afterText }}</div>
          <div v-if="field.afterData && data[field.afterData.field]" class="pt-1 ml-2 ellipsis" :class="afterDataClasses()" :title="data[field.afterData.field]">
            {{ data[field.afterData.field] }}
          </div>
        </div>
      </template>
      <template v-if="checkboxList">
        <component
            :is="formElement(field.component, field.custom)"
            :id="field.name"
            :ref="field.name"
            v-model="data[field.name]"
            v-model:checked="data[field.name]"
            :config="field.props"
            :data="data"
            :disabled="disabled || disabledOnEdit() || disabledIf()"
            :focusElement="field.name == form.focusElement"
            :setFocusElement="setFocusElement"
            :index="index"
            :is-valid="hasErrors(field.name).length === 0"
            :mask="field.mask"
            @error="fieldHasError"
            @selected="selected"
            @hasFile="$emit('hasFile', $event)"
            @uploading="$emit('uploading', $event)"
            @importedData="$emit('importedData', $event)"
            @updateDirty="$emit('updateDirty', $event)"
            @update:modelValue="updateModalValue"
            @copyValue="copyValue"/>
        <div class="flex-1 ml-2">
          <beekman-label :name="field.name"
                         :explanation="field.explanation"
                         :props="field.props"
                         :required="field.required"
                         :validation="form.validations[field.name]"
                         :value="field.text"/>
        </div>
      </template>
    </div>
  </template>
  <template v-if="onlyComponent || field.component == 'Hidden'">
    <component
        :is="formElement(field.component, field.custom)"
        :id="field.name"
        :ref="field.name"
        :text="field.text"
        v-model="data[field.name]"
        v-model:checked="data[field.name]"
        :config="field.props"
        :data="data"
        :disabled="disabled || disabledOnEdit() || disabledIf()"
        :focusElement="field.name == form.focusElement"
        :setFocusElement="setFocusElement"
        :index="index"
        :is-valid="hasErrors(field.name).length === 0"
        :mask="field.mask"
        @error="fieldHasError"
        @update:modelValue="updateModalValue"
        @selected="selected"
        @hasFile="$emit('hasFile', $event)"
        @uploading="$emit('uploading', $event)"
        @importedData="$emit('importedData', $event)"
        @updateDirty="$emit('updateDirty', $event)"
        @copyValue="copyValue"/>
  </template>
  <div v-if="hasErrors(field.name).length" class="error-container" :class="{'flex w-full justify-end pl-3': !onlyComponent && !checkboxList}">
    <beekman-input-error :class="{'w-2/3': !onlyComponent && !checkboxList}" :message="hasErrors(field.name)" :show="hasErrors(field.name).length"/>
  </div>
</template>
<script>
import BeekmanInputError from "@ui/Components/InputError.vue";
import BeekmanEditModalButton from "@ui/Components/Buttons/EditModalButton.vue";
import BeekmanCreateModalButton from "@ui/Components/Buttons/CreateModalButton.vue";
import BeekmanDynamicModalButton from "@ui/Components/Buttons/DynamicModalButton.vue";
import BeekmanLabel from "@ui/Components/Forms/Label.vue";
import EventBus from '@ui/eventBus';
import {defineAsyncComponent} from "vue";
import BeekmanFocusNextField from "@crud/Mixins/Form/FocusNextField.js";
import BeekmanButtons from '@ui/Components/Buttons/Buttons.vue';
import BeekmanLightbox from "@ui/Components/LightBox.vue";

export default {
  mixins: [
    BeekmanFocusNextField,
  ],
  components: {
    BeekmanInputError,
    BeekmanEditModalButton,
    BeekmanCreateModalButton,
    BeekmanDynamicModalButton,
    BeekmanButtons,
    BeekmanLabel,
    BeekmanLightbox,
    'BeekmanCode': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Code.vue')),
    'BeekmanRadio': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Radio.vue')),
    'BeekmanCheckbox': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Checkbox.vue')),
    'BeekmanLookup': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Lookup.vue')),
    'BeekmanLookupButton': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/LookupButton.vue')),
    'BeekmanSelect': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Select.vue')),
    'BeekmanMultiselect': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Multiselect.vue')),
    'BeekmanReadonly': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Readonly.vue')),
    'BeekmanBoolean': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Boolean.vue')),
    'BeekmanProgress': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Progress.vue')),
    'BeekmanHidden': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Hidden.vue')),
    'BeekmanInput': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Input.vue')),
    'BeekmanTextarea': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Textarea.vue')),
    'BeekmanDate': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Date.vue')),
    'BeekmanTime': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Time.vue')),
    'BeekmanFile': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/File.vue')),
    'BeekmanMultipleFiles': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/MultipleFiles.vue')),
    'BeekmanEditor': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Editor.vue')),
    'BeekmanEditorModal': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/EditorModal.vue')),
    'BeekmanToggle': defineAsyncComponent(() => import(/* webpackChunkName: "form-elements" */ '@ui/Components/Forms/Toggle.vue')),
    'BeekmanJson': defineAsyncComponent(() => import(/* webpackChunkName: "form-element" */ '@ui/Components/Forms/Json.vue')),
    'BeekmanHtml': defineAsyncComponent(() => import(/* webpackChunkName: "form-element" */ '@ui/Components/Forms/Html.vue')),
    'BeekmanItemCode': defineAsyncComponent(() => import(/* webpackChunkName: "form-element" */ '@ui/Components/Forms/ItemCode.vue')),
    'BeekmanPassword': defineAsyncComponent(() => import(/* webpackChunkName: "form-element" */ '@ui/Components/Forms/Password.vue')),
    'BeekmanAddress': defineAsyncComponent(() => import(/* webpackChunkName: "form-element" */ '@ui/Components/Forms/Address.vue')),
    'BeekmanSummary': defineAsyncComponent(() => import(/* webpackChunkName: "form-element" */ '@ui/Components/Forms/Summary.vue')),
    'BeekmanImage': defineAsyncComponent(() => import(/* webpackChunkName: "form-element" */ '@ui/Components/Forms/Image.vue')),
    'BeekmanCustomCron': defineAsyncComponent(() => import(/* webpackChunkName: "cron-form-element" */ '@ui/Components/Forms/Custom/Cron.vue')),
  },
  emits: ['update:modelValue', 'update:checked', 'error', 'selected', 'uploading', 'importedData', 'updateDirty', 'hasFile'],
  props: {
    data: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: true
    },
    index: {
      type: [Number, String],
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    labelWidth: {
      type: String,
      default: 'w-1/3'
    },
    elementWidth: {
      type: String,
      default: 'flex-1'
    },
    checkboxList: {
      type: Boolean,
      default: false
    },
    onlyComponent: {
      type: Boolean,
      default: false
    },
    resetFocusElement: {
      type: Boolean,
      default: false
    },
    excludeComponents: {
      type: Array,
      default: ['Spacing', 'Line', 'Title']
    },
    modelValue: [String, Number, Boolean, Object],
    checked: [String, Number, Boolean, Object],
  },
  data() {
    return {
      setFocusElement: false,
      showLightBox: false,
    }
  },
  methods: {
    disabledOnEdit() {
      if (this.form.type === 'edit') {
        return this.field.disabledOnEdit;
      }
      return false;
    },
    disabledIf() {
      if (this.field.props.disabledIf) {
        let disabled = false;
        for (let array of Object.values(this.field.props.disabledIf)) {
          let checkField = this.data[array.field];
          let operator = array.operator;
          let checkValue = array.value;
          if (typeof checkValue === 'boolean' && typeof checkField === 'number') {
            checkField = !!checkField;
          }
          switch (operator) {
            case 'isset':
              disabled = typeof checkField !== 'undefined';
              break;
            case '!isset':
              disabled = typeof checkField === 'undefined';
              break;
            default:
              if (typeof checkValue === 'number') {
                if (checkField == '') {
                  checkField = 0;
                }
                disabled = eval(checkField + operator + checkValue);
              } else {
                switch (operator) {
                  case '===':
                  case '==':
                    disabled = checkField === checkValue;
                    break;
                  case '!==':
                  case '!=':
                    disabled = checkField !== checkValue;
                    break;
                }
              }
          }
          if (this.field.props.disabledIfType === 'And' && !disabled) {
            return disabled;
          } else if (this.field.props.disabledIfType === 'Or' && disabled) {
            return disabled;
          }
        }
        return disabled;
      }
      return false;
    },
    selected(selected) {
      if (this.field.props.imagePreview) {
        if (this.field.component === 'Lookup') {
          this.data[this.field.props.lookup_init_text] = selected[this.field.props.lookup_select_text[0]];
        }
        this.imagePreview = this.setImagePreview();
      }
      this.$emit('selected', selected);
    },
    fieldHasError(error) {
      this.$emit('error', error);
    },
    formElement(type, custom = false) {
      if (custom) {
        return `BeekmanCustom${type}`;
      }
      return `Beekman${type}`;
    },
    hasErrors(field) {
      if (this.errors && Object.keys(this.errors).length) {
        if (this.errors[field]) {
          // multiple errors on field, merge as single string
          if (typeof this.errors[field] === 'object' || typeof this.errors[field] === 'array') {
            let error = '';
            Object.values(this.errors[field]).forEach(value => {
              error += value + ' ';
            });
            return error;
          }
          return this.errors[field];
        }
      }
      return '';
    },
    copyValue(value) {
      if (value !== '') {
        if (typeof value == 'string') {
          value = value.replace(/<[^>]*>?/gm, '');
        }
        // Create new temp element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = value;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {display: 'none'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
        EventBus.$emit('flash-copied', value);
      }
    },
    fieldClasses() {
      let classes = [];
      if (this.field.width) {
        classes.push('w-'+this.field.width);
      } else if(this.field.afterText || this.field.afterData) {
        classes.push('flex-1');
      } else {
        classes.push('w-full');
      }
      if (typeof this.field.props.classes != 'undefined') {
        classes.push(this.field.props.classes);
      }
      if (typeof this.field.props.classWithCondition != 'undefined') {
        let data = this.data[this.field.props.classWithCondition.field];
        if(typeof data != 'undefined' && typeof this.field.props.classWithCondition.conditions[data] != 'undefined'){
          classes.push(this.field.props.classWithCondition.conditions[data]);
        }
      }
      return classes;
    },
    afterDataClasses() {
      let classes = [];
      classes.push(this.field.afterData.classes);
      if (this.field.width != null) {
        classes.push('flex-1');
      }
      return classes;
    },
    updateModalValue(value) {
      this.$emit('update:modelValue', value);
      const components = ['Lookup'];
      if (components.includes(this.field.component) && value != '') {
        setTimeout(() => {
          this.focusNextField();
        }, 1);
      }
    },
    setButtonStyling(buttonClasses) {
      let classes = ['ml-1'];
      if (buttonClasses == '') {
        classes.push('secondary');
      } else {
        classes.push(buttonClasses);
      }
      return classes;
    },
    closeLightBox() {
      this.showLightBox = false;
    },
  },
  computed: {
    imagePreview() {
      let image = '';
      if (this.data.domain) {
        image = this.data.domain;
        if (this.field.component === 'Lookup') {
          image += this.data[this.field.props.lookup_init_text];
        } else {
          image += this.data[this.field.name];
        }
      }
      if (this.field.props.imagePreviewUrl) {
        if (typeof this.data[this.field.name] === 'object') {
          image = URL.createObjectURL(this.data[this.field.name]);
        }
        if (typeof this.data[this.field.name] === 'string') {
          image = this.field.props.imagePreviewUrl;
          image += this.data[this.field.name];
        }
      }
      if (image === '' && this.data[this.field.name] && typeof this.data[this.field.name] === 'string') {
        image = this.data[this.field.name];
      }
      return image;
    },
  },
  watch: {
    resetFocusElement(newValue, oldValue) {
      if (newValue && this.field.name == this.form.focusElement) {
        this.setFocusElement = true;
      }
    }
  }
}
</script>
