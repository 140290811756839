<template>
  <div class="management-menu">
    <beekman-user-theme v-if="!isStandalone"/>

    <div class="border-t border-gray-100" v-if="!isStandalone"></div>

    <beekman-dropdown-link :href="this.$route('profile.show')" v-if="!isStandalone">
      {{ trans('global.profile') }}
    </beekman-dropdown-link>

    <!-- Authentication -->
    <form method="POST" @submit.prevent="logout">
      <beekman-dropdown-link as="button" class="error">
        {{ trans('global.logout') }}
      </beekman-dropdown-link>
    </form>
  </div>
</template>
<script>
import BeekmanDropdownLink from '@ui/Components/DropdownLink.vue';
import BeekmanUserTheme from '@ui/Components/Buttons/UserTheme.vue';
import EventBus from '@ui/eventBus.js';

export default {
  components: {
    BeekmanDropdownLink,
    BeekmanUserTheme,
  },
  props: {
    isStandalone: {
      type: Boolean,
      default: false,
    },
    logoutRedirectUri: {
      type: String,
      default: '',
    },
  },
  methods: {
    logout() {
      EventBus.$emit('logout');
      if (this.logoutRedirectUri != '') {
        this.$inertia.post(this.$route('logout', {redirectUri: this.logoutRedirectUri}));
      } else {
        this.$inertia.post(this.$route('logout'));
      }
    },
  },
  beforeMount() {
    EventBus.$on('want_to_logout', () => {
      this.logout();
    });
  },
  beforeUnmount() {
    EventBus.$off('want_to_logout');
  }
}
</script>
