<template>
  <div v-if="componentLoaded">
    <beekman-dynamic-modal v-if="componentLoaded"
                           :props="componentProps"
                           :component="component"
                           :component-from="componentFrom"
                           @close="closeModal"
                           @refresh="refresh"
                           :url="this.url"
                           :tab-has-lock="tabHasLock"
    />
  </div>
</template>

<script>
import BeekmanDynamicModal from '@ui/Components/Modals/DynamicModal.vue'

export default {
  components: {
    BeekmanDynamicModal,
  },
  data() {
    return {
      componentProps: null,
      componentLoaded: false,
      component: '',
      componentFrom: '',
    };
  },
  emits: ['close', 'loading'],
  props: {
    url: {
      default: ''
    },
    tabHasLock: {
      type: Boolean,
      default: false
    },
  },
  created() {
    this.componentLoaded = false;
    this.load();
  },
  methods: {
    load(reload = false) {
      if (!reload) {
        this.componentLoading = true;
        this.$emit('loading', true);
      }
      axios.get(this.url).then((response) => {
        this.component = response.data.component;
        this.componentFrom = response.data.componentFrom;
        this.componentProps = response.data;
        this.componentLoaded = true;
        this.$emit('loading', false);
      }).catch((error) => {
        this.$emit('loading', false);
      });
    },
    closeModal() {
      this.componentLoaded = false;
      this.$emit('close');
    },
    refresh() {
      this.load(true);
    }
  },
}
</script>
