<template>
  <beekman-select :ref="`select${id}`"
                  :classes="classes"
                  :disabled="disabled || config.disabled || disabledByFilteredField()"
                  :filterable="config.filter"
                  :options="parseOptions(config.options)"
                  :selected-value="selectedValue"
                  :tabindex="index"
                  :resetButton="config.resetButton"
                  width="full"
                  @selected="input($event)"
                  @dblclick="copyValue"/>
</template>
<script>
import BeekmanSelect from '@ui/Components/Select.vue';
import BeekmanFocusField from "@ui/Mixins/FocusField.js";

export default {
  emits: ['update:modelValue', 'copyValue'],
  props: {
    id: String,
    modelValue: [String, Number],
    isValid: [Boolean, Number],
    config: [Array, Object],
    reference: String,
    disabled: [Boolean, Number],
    index: [Number, String],
    data: [Array, Object],
  },
  mixins: [
    BeekmanFocusField,
  ],
  components: {
    BeekmanSelect,
  },
  data() {
    return {selectedValue: this.setSelectedValue()};
  },
  computed: {
    classes() {
      return this.isValid ? '' : 'invalid'
    }
  },
  methods: {
    input($event) {
      this.$emit('update:modelValue', $event);
    },
    copyValue() {
      if (this.selectedValue && (this.disabled || this.config.disabled)) {
        const self = this;
        for (const [key, value] of Object.entries(this.config.options)) {
          if (typeof value === 'object') {
            if (value.value == self.selectedValue) {
              self.$emit('copyValue', value.title);
              return false;
            }
          } else {
            if (key == self.selectedValue) {
              self.$emit('copyValue', value);
              return false;
            }
          }
        };
      }
    },
    parseOptions(options) {
      if (options) {

        if (options.length) {
          return this.filteredByField(options);
        }
        const list = [];
        for (const [key, value] of Object.entries(options)) {
          list.push({
            title: value,
            value: key,
            filter: value
          });
        }
        return this.filteredByField(list);
      }
      return [];
    },
    setSelectedValue() {
        if (typeof this.modelValue != 'undefined' && this.modelValue !== '') {
            return this.modelValue;
        }
        if (this.config && this.config.defaultValue !== '') {
            return this.config.defaultValue
        }
        return ''
    },
    disabledByFilteredField() {
      if (typeof this.config.filteredByField != 'undefined') {
        if (this.data[this.config.filteredByField] != '') {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    filteredByField(options) {
      if (typeof this.config.filteredByField != 'undefined' && this.data[this.config.filteredByField] != '') {
        let filteredOptions = [];
        for (let values of Object.values(options)) {
          if (values.filteredBy == this.data[this.config.filteredByField]) {
            filteredOptions.push(values);
          }
        }
        options = filteredOptions;
      }

      return options;
    }
  },
  watch: {
    modelValue(newValue, oldValue) {
      this.selectedValue = newValue;
    },
  },
}
</script>