export default {
    install(app, options) {
        // translations property
        app.config.globalProperties.$translations = [];
        // translation methods
        app.config.globalProperties.$trans = function (key, replace = {}) {
            let match = '';
            if (typeof key === 'string') {
                key = key.toLowerCase();
            }
            match = app.config.globalProperties.$translations[key];
            if (match) {
                Object.keys(replace).forEach(function (key) {
                    match = match.replace(':' + key, replace[key]);
                });
                return match;
            }
            return key;
        }
        app.config.globalProperties.$mergeTranslations = function (translations) {
            if (typeof translations !== 'undefined') {
                Object.keys(translations).forEach(function (trans) {
                    const exists = app.config.globalProperties.$translations.some((transKey) => {
                        return trans === transKey;
                    })
                    if (!exists) {
                        app.config.globalProperties.$translations[trans.toLowerCase()] = translations[trans];
                    }
                });
            }
        }
    }
}
