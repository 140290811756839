<template>
  <div class="overlay-modal bg-blue-50"
       @keydown.esc="$emit('close')">
    <Component
        :is="modalComponent"
        ref="modal"
        :modal-data="props"
        :url="url"
        @close="$emit('close')"
    />
  </div>
</template>

<script>
import ImportComponent from "@crud/Mixins/ImportComponent.js";

export default {
  mixins: [ImportComponent],
  data() {
    return {
      show: true,
    }
  },
  emits: ['close'],
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
    url: {
      default: ''
    },
  },
  computed: {
    modalComponent() {
      return this.importComponent(this.props.component, this.props.componentFrom);
    },
  },
}
</script>
