<template>
  <input type="checkbox"
         ref="checkbox"
         :indeterminate="isIndeterminate"
         :value="modelValue"
         :id="id"
         v-model="proxyChecked"
         class="checkbox"
         :tabindex="index"
         @keydown.space.stop="onSpace"
         :disabled="disabled || config.disabled">
</template>
<script>
import BeekmanFocusField from "@ui/Mixins/FocusField.js";

export default {
  emits: ['update:checked', 'error', 'update:modelValue'],
  mixins: [
    BeekmanFocusField,
  ],
  data() {
    return {
      isIndeterminate: false,
      indeterminateState: false,
    };
  },
  props: {
    id: String,
    modelValue: [Boolean, Number],
    isValid: [Boolean, Number],
    config: {
      type:[Array, Object],
      default: {}
    },
    indeterminateData: {
      type:[Array, Object],
      default: {}
    },
    reference: String,
    type: String,
    disabled: [Boolean, Number],
    index: [Number, String],
    data: [Array, Object],
    checked: [Boolean, Number],
  },
  mounted() {
    this.isIndeterminate = this.checkIndeterminate(this.id);
  },
  computed: {
    proxyChecked: {
      get() {
        let value = !!this.checked;
        if (typeof this.checked == 'string') {
          if (this.checked == 'true' || this.checked == '1') {
            value = true;
          } else if (this.checked == 'false' || this.checked == '0') {
            value = false;
          }
        }
        if (this.indeterminateState === true) {
          value = true;
        }
        if (this.isIndeterminate) {
          value = null;
        }
        return value;
      },
      set(val) {
        this.$emit('update:modelValue', val);
        this.$emit('update:checked', val);
      },
    },
  },
  methods: {
    checkIndeterminate(name) {
      const count = this.indeterminateData.length;

      if (count > 1) {
        const valueFirstItem = this.indeterminateData[0][name];
        const same = this.indeterminateData.filter((fieldData) => {
          return fieldData[name] == valueFirstItem;
        });

        if (count === same.length) {
          this.indeterminateState = Boolean(valueFirstItem);
        } else {
          this.indeterminateState = null;
          return true;
        }
      }
      if (count === 1) {
        this.indeterminateState = Boolean(this.indeterminateData[0][name]) ?? false;
      }
      return false;
    },
    onSpace(event) {
      event.preventDefault();
      this.proxyChecked = !this.proxyChecked;
    },
  },
}
</script>
