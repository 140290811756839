import './bootstrap.js';
import './echo.js';
import './../../../resources/scss/app.scss';
import './../../../resources/scss/dark.scss';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// Import modules...
import {createApp, h, reactive} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePages, vueInit} from "@ui/setup.js";
import 'floating-vue/dist/style.css';

/* register modalobservable */
window.modals = reactive({
    active: '',
    list: [],
    count: 0,
});

window.locks = reactive({
    list: [],
    hasLocks: 0,
});

window.response = reactive({
    shouldReload: 0,
});

createInertiaApp({
    resolve: name => {
        return resolvePages(name);
    },
    setup({el, App, props, plugin}) {
        vueInit(
            createApp({render: () => h(App, props)})
                .use(plugin)
        ).mount(el);
    },
});


