<template>
  <modal v-if="show && dataLoaded" :show="show && dataLoaded" :width="30" @close="close">
    <modal-header>
      {{trans('addLink')}}
    </modal-header>
    <div class="modal-content">
      <div class="flex flex-wrap">
        <template v-for="(field, index) in form">
          <div class="element-row justify-between">
            <div class="w-1/6">
              <label>{{field.text}}</label>
            </div>
            <div class="element-container pl-2 flex-1">
              <beekman-select v-if="field.component == 'Select'"
                              :id="field.name"
                              :ref="field.name"
                              :label="field.text"
                              :options="field.props.options"
                              v-model="useForm[field.name]"
                              :data="useForm"
                              :config="field.props"
                              width="full"
                              :isValid="true"
                              :disabled="isDisabled(field.name)"
                              @selected="changedField(field.name, $event)"
              />
              <beekman-input v-if="field.component == 'Input'"
                             :id="field.name"
                             :ref="field.name"
                             :placeholder="field.text"
                             :isValid="true"
                             type="text"
                             :config="field.props"
                             :disabled="isDisabled(field.name)"
                             v-model="useForm[field.name]"
                             @update:modelValue="changedField(field.name, $event)"
              />
              <div v-if="field.name == 'search' && (useForm.search != '' || searchResults.length > 0)">
                <ul class="mt-2 h-24 overflow-y-auto">
                  <li v-if="searchResults.length == 0">{{trans('noResults')}}</li>
                  <li v-for="searchResult in searchResults">
                    <button class="text-left hover:underline flex justify-between w-full pr-2" @click="setUrl(Object.keys(searchResult)[0])" v-html="Object.values(searchResult)[0]"></button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <modal-footer ref="footerButtons">
      <template #footerButtons>
        <beekman-button styling="secondary mr-2" @click="close">
          {{ __('global.close') }}
        </beekman-button>
        <beekman-button styling="primary"
                        @click="addLink"
                        :disabled="this.useForm.url == '' || this.useForm.text == ''">
          {{ __('global.add') }}
        </beekman-button>
      </template>
    </modal-footer>
  </modal>
</template>
<script>
import Modal from '@ui/Components/Modals/Modal.vue';
import ModalHeader from '@ui/Components/Modals/HeaderNoButtons.vue';
import ModalFooter from "@ui/Components/Modals/Footer.vue";
import BeekmanButton from "@ui/Components/Button.vue";
import BeekmanSelect from "@ui/Components/Forms/Select.vue";
import BeekmanInput from "@ui/Components/Forms/Input.vue";
import {useForm} from "@inertiajs/vue3";

export default {
  components: {
    Modal,
    ModalHeader,
    ModalFooter,
    BeekmanButton,
    BeekmanSelect,
    BeekmanInput,
  },
  emits: ['close', 'addLink'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    applicationId: {
      type: Number,
    },
    languageId: {
      type: Number,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      form: {},
      useForm: {},
      dataLoaded: false,
      searchResults: [],
      defaultHtmlTypes: [],
      onlySearchTypes: [],
    };
  },
  created() {
    this.dataLoaded = false;
    axios.get(this.$route('system-management.custom-links.types')).then((response) => {
      this.$mergeTranslations(response.data.translations);
      this.form = response.data.form;
      this.defaultHtmlTypes = response.data.defaultHtmlTypes;
      this.onlySearchTypes = response.data.onlySearchTypes;
      this.useForm = response.data.formData;
      this.useForm.type = this.data.url.split(':')[0];
      this.useForm.text = this.data.text;
      this.useForm.title = this.data.title;
      this.useForm.url = this.data.url;
      this.useForm.classes = this.data.classes;
      this.useForm.target = this.data.target;
      this.dataLoaded = true;
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    changedField(field, type) {
      if (field == 'type') {
        this.useForm.search = '';
        if (!this.onlySearchTypes.includes(this.useForm.type) && !this.defaultHtmlTypes.includes(this.useForm.type)) {
          this.getTypeData();
        }
        if (this.onlySearchTypes.includes(this.useForm.type) || this.useForm.type == '') {
          this.searchResults = [];
        }
        if (this.defaultHtmlTypes.includes(this.useForm.type)) {
          this.useForm.url = this.useForm.type+':';
          this.searchResults = [];
        }
      }
      if (field == 'search') {
        this.searching();
      }
    },
    isDisabled(field) {
      let disabled = false;
      if (field == 'search' && (this.useForm.type == '' || this.defaultHtmlTypes.includes(this.useForm.type))) {
        disabled = true;
      }
      if (field == 'url' && (this.useForm.type != '' && !this.defaultHtmlTypes.includes(this.useForm.type))) {
        disabled = true;
      }
      if (field == 'text' && this.data.text != '') {
        disabled = true;
      }
      return disabled;
    },
    setUrl(value) {
      this.useForm.url = this.useForm.type+'://'+value;
      if (this.onlySearchTypes.includes(this.useForm.type)) {
        this.useForm.search = '';
        this.searchResults = [];
      }
    },
    addLink() {
      if (this.useForm.url != '' && this.useForm.text != '') {
        if (this.useForm.target == 0) {
          this.useForm.target = '';
        }
        data = {
          href: this.useForm.url,
          text: this.useForm.text,
          title: this.useForm.title,
          target: this.useForm.target,
          class: this.useForm.classes,
        };
        this.$emit('addLink', data);
        this.close();
      }
    },
    searching() {
      this.search();
    },
    search: window._.debounce(function () {
      if (this.useForm.search.length > 1 || !this.onlySearchTypes.includes(this.useForm.type)) {
        this.getTypeData();
      }
    }, 300),
    getTypeData() {
      if (this.useForm.type != '') {
        let attributes = {
          type: this.useForm.type,
          applicationId: this.applicationId,
          languageId: this.languageId,
        };
        if (this.useForm.search != '') {
          attributes.search = this.useForm.search;
        }
        axios.get(this.$route('system-management.custom-links.search', attributes)).then((response) => {
          this.searchResults = response.data;
        });
      }
    },
  }
}
</script>
