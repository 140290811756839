<template>
  <div v-if="data && visible" class="fixed inset-0" style="z-index: 500">
    <div class="fixed inset-0 transform transition-all absolute w-full h-full backdrop-blur-xs">
      <div class="flex h-screen w-full justify-center items-center">
        <div class="bg-white border border-red-500 rounded width-90 height-90">
          <div class="p-4 text-white bg-red-500 text-xl" @click="visible = false">
            Development Debugger
          </div>
          <div class="overflow-y-auto" style="height: calc(90vh - 50px)">
            <div class="p-3" v-if="data.frontend">
              <div>
                <div class="text-xl text-theme font-semibold">{{ data.frontend.data.url }}</div>
                <div class="text-lg font-normal">{{ data.frontend.data.message }}</div>
                <div class="text-xs text-theme text-opacity-50 underline">
                  <a target="_blank" :href="data.frontend.route">Bekijk exceptie</a>
                </div>
              </div>
              <div class="mt-5">
                <div class="bg-gray-100 p-3 rounded text-theme border-t border-gray-300 shadow-inner text-sm overflow-auto">
                  <pre>{{ data.frontend.data.stackTrace }}</pre>
                </div>
              </div>
            </div>
            <div class="p-3" v-if="data.exception">
              <div>
                <div class="text-xl">
                  <span class="text-red-500">{{ data.exception.status ?? 500 }}</span>
                  <span> - {{ data.exception.statusText ?? '' }}</span>
                </div>
                <div class="my-5">
                  <div>
                    <div class="text-xl text-theme font-semibold">
                      {{ data.exception.exception ?? '' }}
                    </div>
                    <div class="text-lg tfont-normal">
                      {{ stringUntil(data.exception.message, 'Stack trace:') }}
                    </div>
                    <div class="text-base text-gray-400 font-thin">
                      Called in <strong>{{ data.exception.file ?? '' }}</strong> on line <strong>{{ data.exception.line ?? '' }}</strong>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-100 p-3 rounded text-webid1 border-t border-gray-300 shadow-inner text-sm overflow-auto">
                  <pre>{{ stringAfter(data.exception.message, 'Stack trace:') }}</pre>
                </div>
              </div>
            </div>

            <div v-if="data.dump">
              {{ showModal(data.dump) }}
            </div>
            <div v-if="data.ignition">
              {{ showModal(data.ignition) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '@ui/eventBus.js';

export default {
  data() {
    return {
      data: null,
      visible: false,
      modal: null,
      listener: null,
      timeLeft: 0,
      timeout: null,
    }
  },
  created() {
    // debug
    EventBus.$on('debug', (data) => {
      //console.log('debug', data);
      this.visible = true;
      this.data = data;
      EventBus.$emit('stopCloseModal');
    });
  },
  methods: {
    countDown(left) {
      this.timeLeft = left;
      if (left > 0) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          left = left - 1;
          this.countDown(left);
        }, 1000);
      } else {
        this.modalHide();
      }
    },
    stringUntil(string, until) {
      return string.split(until)[0]
    },
    stringAfter(string, until) {
      return string.split(until)[1]
    },
    hasStringAfter(string, until) {
      const parts = string.split(until);
      if (parts[1] === undefined) {
        return false;
      }
      return true;
    },
    modalHide() {
      this.modal.outerHTML = '';
      this.modal = null;
      this.visible = false;
      document.body.style.overflow = 'visible';
      document.removeEventListener('keydown', this.listener);
      clearTimeout(this.timeout);
    },
    modalHideOnEscape(event) {
      if (event.keyCode === 27) {
        this.modalHide();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    showModal(html) {
      if (this.modal !== null) {
        this.modalHide();
      }
      // close modal after 30 seconds
      // this.countDown(30);

      const page = document.createElement('html');
      page.innerHTML = html;
      page.querySelectorAll('a').forEach(a => a.setAttribute('target', '_top'));

      this.modal = document.createElement('div');
      this.modal.style.position = 'fixed';
      this.modal.style.width = '100vw';
      this.modal.style.borderRadius = '5px';
      this.modal.style.height = '100vh';
      this.modal.style.padding = '50px';
      this.modal.style.boxSizing = 'border-box';
      this.modal.classList.add("backdrop-blur-xs");
      this.modal.style.zIndex = 200000;
      this.modal.addEventListener('click', () => this.modalHide());

      let headerContainer = document.createElement('div');
      headerContainer.style.backgroundColor = 'rgb(239 68 68)';
      headerContainer.style.padding = '1rem';
      headerContainer.style.borderTopRightRadius = '0.25rem';
      headerContainer.style.borderTopLeftRadius = '0.25rem';
      headerContainer.style.color = '#fff';
      let header = document.createElement('div');
      header.innerHTML = 'Development Debugger';
      header.classList.add('text-lg');
      headerContainer.append(header);

      this.modal.appendChild(headerContainer);

      const iframe = document.createElement('iframe');
      iframe.style.backgroundColor = 'white';
      iframe.style.borderBottomRightRadius = '0.25rem';
      iframe.style.borderBottomLeftRadius = '0.25rem';
      iframe.style.border = '1px solid rgb(239 68 68)';
      iframe.style.width = '100%';
      iframe.style.height = '90%';
      this.modal.appendChild(iframe);

      document.body.prepend(this.modal);
      document.body.style.overflow = 'hidden';

      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(page.outerHTML);
      iframe.contentWindow.document.close();

      this.listener = this.modalHideOnEscape.bind(this);
      document.addEventListener('keydown', this.listener);
    },
    handleKeydown(event) {
      if (event.keyCode === 27) {
        if (this.visible) {
          event.preventDefault();
          event.stopPropagation();
          this.visible = false;
          // use timeout to fix modal closing as well
          setTimeout(() => {
            EventBus.$emit('proceedCloseModal');
          }, 100);
        }
      }
    },
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    clearTimeout(this.timeout);
  },
};
</script>
