<template>
  <img v-if="application.image && application.image.length" :src="application.image" alt="Beekman B.V." />
</template>

<script>
import { mapState } from 'pinia';
import { userStore } from '@ui/Stores/User.js';

export default {
  computed: {
    ...mapState(
        userStore, ['application'],
    ),
  },
}
</script>
