export default {
    methods: {
        focusButton(buttons, direction) {
            let length = buttons.length - 1;
            let found = false;
            let i = 0;
            for (i; i <= length; i++) {
                let button = buttons[i];
                if(button === document.activeElement){
                    found = true;
                    let setI = 0;
                    if(direction == 'prev'){
                        if(i > 0){
                            setI = i - 1;
                        }else{
                            setI = length;
                        }
                    }else if(direction == 'next'){
                        if(i < length){
                            setI = i + 1;
                        }
                    }
                    buttons[setI].focus();
                    break;
                }
            }
            if(found == false){
                buttons[length].focus();
            }
        },
    },
};
