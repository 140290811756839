<template>
  <div class="flex" @dblclick="forceDisabled() ? $emit('copyValue', modelValue):''">
    <input :id="id"
           ref="input"
           v-mask="mask"
           v-bind="customAttributes"
           :autocomplete="autocomplete"
           :class="getClasses"
           :readonly="forceDisabled()"
           :max="max ? max : false"
           :min="min.length != 0 ? min : false"
           :placeholder="placeholder"
           :step="step ? step : false"
           :tabindex="index"
           :type="fieldType"
           :maxlength="config.maxLength ? config.maxLength : ''"
           :value="modelValue ?? (typeof config.defaultValue != 'undefined' ? config.defaultValue : '')"
           @input="checkInput($event)"
           @focus="$event.target.select()">
    <div v-if="config.icon"
         :disabled="forceDisabled()"
         class="px-2 rounded-r cursor-pointer text-xs bg-gray-300 flex items-center justify-center">
      <i :class="config.icon"></i>
    </div>
  </div>
</template>
<script>
import BeekmanFormMask from "@ui/Mixins/FormMask";
import BeekmanFocusField from "@ui/Mixins/FocusField";

export default {
  emits: ['update:modelValue', 'error', 'copyValue'],
  props: {
    id: String,
    autocomplete: [String],
    modelValue: [String, Number],
    isValid: [Boolean, Number],
    config: {
      type: [Array, Object],
      required: false,
      default() {
        return {};
      }
    },
    customAttributes: [Object],
    classes: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    reference: String,
    type: String,
    mask: String,
    disabled: [Boolean, Number],
    index: [Number, String],
    data: [Array, Object],
    placeholder: String,
  },
  mixins: [
    BeekmanFormMask,
    BeekmanFocusField,
  ],
  methods: {
    focusInput() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    checkInput(event) {
      if(typeof this.config !== 'undefined' && this.config.type == 'number') {
        if(this.max !== null && (event.target.value > this.max || event.target.value.length > this.max.toString().length)) {
          event.target.value = this.max;
        }
        if (event.target.value != '' && this.min > event.target.value) {
          event.target.value = this.min;
        }
      }
      this.$emit('update:modelValue', event.target.value);
    },
    forceDisabled() {
      if (this.config && this.config.disabled) {
        return this.config.disabled;
      }
      return this.disabled;
    },
  },
  data() {
    let data = {min: '', max: '', step: ''};
    if (this.config && this.config.type == 'number') {
      let max = this.config.max ?? null;
      if (this.config.maxFromValue){
        max = this.data[this.config.maxFromValue];
      }
      data = {
        min: this.config.min ?? 0,
        max: max,
        step: this.config.step ?? 1
      };
    }
    return data;
  },
  computed: {
    fieldType() {
      if (this.config) {
        if (this.config.type) {
          return this.config.type;
        }
      }
      return this.type;
    },
    getClasses() {
      let classes = this.classes;
      if (!this.isValid) {
        classes.push('invalid');
      } else if (classes.indexOf('invalid') > -1) {
        classes.splice(classes.indexOf('invalid'), 1)
      }
      if (this.fieldType === 'number') {
        classes.push('text-right');
      }
      return classes;
    },
  },
}
</script>
