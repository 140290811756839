<template>
  <div>
    <beekman-dropdown align="right" width="96" :content-classes="contentClasses"
                      :forceOpen="showLatest === true" :forceClose="showLatest === false"
                      @opened="isOpen = true" @closed="isOpen = false">
      <template #trigger="{ open }">
        <div class="relative inline-block flex items-center" @click="dontShowLatest" style="height: 3.2rem">
          <i class="fal fa-bell text-themeText text-xl cursor-pointer"/>
          <div v-if="count" class="absolute top-1 left-2 items-center justify-center bg-red-600 rounded-full h-4">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
            <span class="relative pl-1 pr-1.5 py-1 text-red-100" style="line-height: 0.6rem; font-size: 0.7rem">{{ count }}</span>
          </div>
        </div>
      </template>

      <template #content>
        <div class="max-h-96 overflow-y-auto rounded-t bg-white">
          <beekman-notification v-if="notifications.length" v-for="notification in filteredNotifications"
                                :notification="notification"
                                :key="notification.id"
                                @click="dontShowLatest"
                                @markedAsRead="remove"/>
        </div>
        <div v-if="count && !showLatest" class="flex items-center justify-center bg-gray-50 p-2 rounded-b">
          <button @click="markAllAsRead" class="hover:text-gray-600">{{ $trans('global.notifications.markAllAsRead') }}</button>
        </div>
      </template>
    </beekman-dropdown>
  </div>
</template>

<script>
import BeekmanDropdown from '@ui/Components/Dropdown.vue';
import BeekmanNotification from '@ui/Components/Notification.vue';

export default {
  components: {
    BeekmanDropdown,
    BeekmanNotification,
  },
  computed: {
    count() {
      if (this.notifications.length < 100) {
        return this.notifications.length;
      }
      return '99+';
    },
    filteredNotifications() {
      if (this.showLatest) {
        return [this.notifications[0]];
      }
      return this.notifications;
    }
  },
  methods: {
    remove(id) {
      this.notifications.splice(this.notifications.findIndex(notification => notification.id === id), 1);
    },
    fetchNotifications() {
      window.axios.get(route('notifications')).then((response) => {
        if (response && response.data) {
          this.notifications = response.data;
        }
      });
    },
    initBroadcast() {
      if (this.$user && this.$user.channel) {
        window.Echo.private(this.$user.channel)
            .notification((notification) => {
              this.notifications.unshift(notification);
              if (!this.isOpen) {
                this.showLatest = true;
                this.countDown(5);
              }
            })
            .listen('.notifications.markAsRead', (event) => {
              if (event.notificationId) {
                this.remove(event.notificationId);
              }
            })
            .listen('.notifications.markAllAsRead', (event) => {
              this.fetchNotifications();
            });

      }
    },
    markAllAsRead() {
      window.axios.post(route('notifications.markAllAsRead')).then((response) => {
        if (response && response.data && response.data.success) {
          this.fetchNotifications();
        }
      });
    },
    dontShowLatest() {
      this.showLatest = false;
    },
    countDown(left) {
      this.timeLeft = left;
      if (left > 0) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.countDown(left - 1);
        }, 1000)
      } else {
        clearTimeout(this.timeout);
        this.dontShowLatest();
      }
    },
  },
  created() {
    this.fetchNotifications();
    this.initBroadcast()
  },
  data() {
    return {
      timeLeft: 0,
      timeout: null,
      showLatest: false,
      contentClasses: {
        default: () => ['bg-white max-h-96']
      },
      notifications: [],
      isOpen: false,
    };
  }
};

</script>
