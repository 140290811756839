<template>
  <div v-if="componentLoaded">
    <Component :is="createComponent"
               :form="data"
               :show="true"
               :url="url"
               @abort="closeCreateModal"
               @submit="submitCreateModal"/>
  </div>
</template>

<script>
import ImportComponent from "@crud/Mixins/ImportComponent.js";

export default {
  mixins: [ImportComponent],
  data() {
    return {
      data: null,
      componentLoaded: false,
    };
  },
  emits: ['close', 'submit', 'selectApplications'],
  props: {
    url: {
      default: ''
    },
    copiedData: {
      default: null
    },
    selectedApplicationId: {
      default: null,
    },
  },
  created() {
    this.getData();
  },
  computed: {
    createComponent() {
      return this.importComponent('Modals/Create', 'crud');
    },
  },
  watch: {
    selectedApplicationId() {
      this.getData();
    },
  },
  methods: {
    closeCreateModal() {
      this.componentLoaded = false;
      this.$emit('close');
    },
    submitCreateModal(props) {
      this.componentLoaded = false;
      this.$emit('submit', props);
    },
    getData() {
      this.componentLoaded = false;
      let url = this.url;
      if (this.selectedApplicationId != null) {
        url += '?application_id='+this.selectedApplicationId;
      }
      axios.get(url).then((response) => {
        this.data = response.data;
        if (this.data.showSelectApplication && this.data.applications) {
          this.$emit('selectApplications', this.data.applications)
          return;
        }
        if (this.copiedData) {
          if (this.copiedData.data) {
            for (let [key, value] of Object.entries(this.copiedData.data)) {
              this.data.form[key] = value;
            };
          }
          if (this.copiedData.copyAfterCreate) {
            this.data.form.copyAfterId = this.copiedData.copyAfterCreate.id;
            this.data.form.copyAfterData = this.copiedData.copyAfterCreate.data;
          }
        }
        this.componentLoaded = true;
      });
    },
  },
}
</script>
