<template>
  <portal to="lightbox">
    <transition mode="out-in" name="lightbox">
      <div
          v-if="media"
          v-show="lightBoxOn"
          ref="container"
          tabindex="-1"
          class="flex h-full w-full justify-center items-center box-border absolute inset-0"
          style="background-color: rgba(0, 0, 0, 0.7); z-index: 9999999">
        <div class="absolute top-0 right-0 rounded align-middle" style="background-color: rgba(0, 0, 0, 0.3);">
          <button type="button" class="p-4" @click.stop="closeLightBox()">
            <i class="fal fa-times text-white"/>
          </button>
        </div>
        <div class="absolute top-0 left-0 p-4">
          <div class="text-gray-100 text-base">
            {{ select + 1 }} / {{ media.length }}
          </div>
        </div>
        <div class="max-w-5xl">
          <div class="relative">
            <img v-if="media[select].type !== 'video'"
                 :key="media[select].src"
                 v-lazy="{
                  src: media[select].src,
                  loading: media[select].src,
                  error: media[select].src,
                }"
                 :srcset="media[select].srcset || ''"
                 class="max-w-full"
                 style="max-height: calc(100vh - 140px)"
                 :alt="media[select].caption"
            >
            <video
                v-else
                ref="video"
                :key="media[select].sources[0].src"
                :width="media[select].width"
                :height="media[select].height"
                :autoplay="media[select].autoplay"
                controls
            >
              <source
                  v-for="source in media[select].sources"
                  :key="source.src"
                  :src="source.src"
                  :type="source.type"
              >
            </video>
            <!-- caption -->
            <div
                v-show="showCaption && media[select].caption"
                class="absolute bottom-0 text-white bg-black bg-opacity-60 h-12 w-full text-center p-3"
                v-html="media[select].caption"
            />
          </div>
        </div>
        <div class="absolute bottom-14 bg-black bg-opacity-40" v-if="showThumbs && media.length > 1">
          <div
              v-for="(image, index) in imagesThumb"
              v-show="index >= thumbIndex.begin && index <= thumbIndex.end"
              :key="typeof image.src === 'string' ? `${image.src}${index}` : index"
              class="rounded-sm shadow-xl m-0.5 inline-block w-20 hover:opacity-100 cursor-zoom-in"
              :class="{'opacity-70': select !== index}"
              @click.stop="showImage(index)"
          >
            <img :src="image.src" style="margin:0 auto;" />
            <i v-if="image.type === 'video'" class="fal fa-play"/>
          </div>
        </div>
        <button
            v-if="media.length > 1"
            type="button"
            class="absolute top-2/4 left-3 cursor-pointer p-4 rounded flex align-middle"
            style="background-color: rgba(0, 0, 0, 0.2);"
            @click.stop="previousImage()"
        >
          <i class="fal fa-arrow-left fa-lg text-white ml-1"/>
        </button>

        <button
            v-if="media.length > 1"
            type="button"
            class="absolute top-2/4 right-3 cursor-pointer p-4 rounded flex align-middle"
            style="background-color: rgba(0, 0, 0, 0.2);"
            @click.stop="nextImage()"
        >
          <i class="fal fa-arrow-right fa-lg text-white ml-1"/>
        </button>
      </div>
    </transition>
  </portal>
</template>

<script>
// { // For image
//   thumb: 'https://s3-us-west-1.amazonaws.com/powr/defaults/image-slider2.jpg',
//   src: 'https://s3-us-west-1.amazonaws.com/powr/defaults/image-slider2.jpg',
//   caption: 'caption to display. receive <html> <b>tag</b>', // Optional
// },
// { // For video
//   thumb: 'https://s3-us-west-1.amazonaws.com/powr/defaults/image-slider2.jpg',
//   sources: [
//     {
//       src: 'https://www.w3schools.com/html/mov_bbb.mp4',
//       type: 'video/mp4'
//     }
//   ],
//   type: "video",
//   caption: '<h4>Monsters Inc.</h4>',
//   width: 800, // required
//   height: 600, // required
//   autoplay: true //Optional to autoplay video when lightbox opens
// }
export default {
  props: {
    media: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
    startAt: {
      type: Number,
      default: 0,
    },
    numberOfThumbs: {
      type: Number,
      default: 7,
    },
    showThumbs: {
      type: Boolean,
      default: true,
    },
    siteLoading: {
      type: String,
      default: '',
    },
    showCaption: {
      type: Boolean,
      default: true,
    },
    lengthToLoadMore: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    'onOpened',
    'onClosed',
  ],
  data() {
    return {
      select: this.startAt,
      lightBoxOn: this.show,
      timer: null,
    }
  },
  computed: {
    thumbIndex() {
      const halfDown = Math.floor(this.numberOfThumbs / 2);
      if (this.select >= halfDown && this.select < this.media.length - halfDown) {
        return {
          begin: this.select - halfDown + (1 - (this.numberOfThumbs % 2)),
          end: this.select + halfDown,
        };
      }
      if (this.select < halfDown) {
        return {
          begin: 0,
          end: this.numberOfThumbs - 1,
        };
      }
      return {
        begin: this.media.length - this.numberOfThumbs,
        end: this.media.length - 1,
      };
    },
    imagesThumb() {
      if (this.siteLoading) {
        return this.media.map(({ thumb, type }) => ({
          src: thumb,
          type,
          loading: this.siteLoading,
          error: this.siteLoading,
        }));
      }
      return this.media.map(({ thumb, type }) => ({
        src: thumb,
        type,
      }));
    }
  },
  watch: {
    lightBoxOn(value) {
      this.onToggleLightBox(value);
    },
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.$refs.container.focus();
  //   });
  // },
  methods: {
    onLightBoxOpen() {
      this.$emit('onOpened');
    },
    onLightBoxClose() {
      this.$emit('onClosed');
    },
    onToggleLightBox(value) {
      if (value) {
        this.onLightBoxOpen();
      } else {
        this.onLightBoxClose();
      }
    },
    showImage(index) {
      this.select = index;
      this.lightBoxOn = true;
    },
    handleKeydown(event) {
      event.stopPropagation();
      event.preventDefault();
      if (event.key === 'ArrowLeft') {
        this.previousImage(); // left arrow
      }
      if (event.key === 'ArrowRight') {
        this.nextImage(); // right arrow
      }
      if (event.key === 'Escape') {
        this.closeLightBox(); // esc
      }
      event.stopPropagation();
      event.preventDefault();
    },
    closeLightBox() {
      this.lightBoxOn = false;
    },
    nextImage() {
      this.select = (this.select + 1) % this.media.length;
    },
    previousImage() {
      this.select = (this.select + this.media.length - 1) % this.media.length;
    },
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  mounted() {
    this.$nextTick(()=>{
      this.$refs.container.focus();
    });
  }
}
</script>

<style scoped>
.lightbox-transition-enter-active,
.lightbox-transition-leave-active {
  transition: opacity .2s ease;
}

.lightbox-transition-enter-from,
.lightbox-transition-leave-to {
  opacity: 0;
}
</style>
