<template>
  <portal to="modal">
    <div v-if="show" :id="`modal-${uidModal}`" :style="{'z-index': 99 + numberOfModals()}"
         class="fixed inset-0 sm:p-0" :class="modalClasses()">
      <div v-show="show && activeBackdrop()" class="fixed inset-0 transform transition-all modal-overlay">
        <div class="absolute w-full h-full"
             :class="{
                'backdrop-blur-xs': backdropBlur,
                'bg-gray-500 opacity-50': !backdropBlur
              }"></div>
      </div>
      <div v-show="show"
           v-drag="{ handle: `#${dragHandle}` }"
           class="flex h-screen w-full justify-center items-center" :class="{'opacity-0':!modalLoaded}">
        <div :class="[`width-${width} height-${height}`, positionClasses()]"
             class="modal">
          <div :id="dragHandle" class="absolute w-full h-16"></div>
          <slot></slot>
        </div>
      </div>
    </div>
  </portal>
</template>
<style>
@media only screen and (max-width: 900px) {
  .modal {
    width: 99vw !important;
  }
}
</style>
<script>
import BeekmanFocusButton from "@ui/Mixins/FocusButton.js";
import Modal from '@ui/Components/Modals/Modal.vue';
import EventBus from '@ui/eventBus.js';
import BeekmanFocusNextField from "@crud/Mixins/Form/FocusNextField.js";

export default {
  props: {
    show: {
      default: false
    },
    width: {
      default: 'auto'
    },
    height: {
      default: 'auto'
    },
    position: {
      default: []
    },
    closeable: {
      default: true
    },
    closeEscape: {
      default: true
    },
    url: {
      default: ''
    },
    updateUid: {
      default: false
    },
    backdropBlur: {
      default: false
    },
    modalLoaded: {
      default: true
    }
  },
  emits: [
    'close',
    'modalActivated',
    'refresh',
  ],
  data() {
    let uuid = this.uuid();
    return {
      dragHandle: `drag-handle-${uuid}`,
      uidModal: uuid,
      uid: uuid,
      canCloseModal: true,
    };
  },
  mixins: [
    BeekmanFocusButton,
    BeekmanFocusNextField,
  ],
  methods: {
    close() {
      if (window.modals.active === this.uid) {
        this.$emit('close');
      }
    },
    activeBackdrop() {
      this.$emit('modalActivated', this.uid);
      return window.modals.active === this.uid;
    },
    numberOfModals() {
      return window.modals.count;
    },
    modals() {
      return window.modals;
    },
    uuid() {
      let uuid = "", i, random;
      for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;

        if (i == 8 || i == 12 || i == 16 || i == 20) {
          uuid += "-"
        }
        uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
      }
      return uuid;
    },
    handleKeydown(e) {
      if (window.modals.active === this.uid) {
        switch (e.keyCode) {
            //arrowLeft/Right
          case 37:
          case 39:
            const ignoreFocus = ['INPUT', 'TEXTAREA'];
            let direction = 'prev';
            if (e.keyCode == 39) {
              direction = 'next';
            }
            if (!ignoreFocus.includes(document.activeElement.tagName)) {
              let footerButtons = this.$parent.$refs.footerButtons;
              if (typeof footerButtons == 'undefined') {
                if (this.$parent.$refs.modal && typeof this.$parent.$refs.modal.$children != 'undefined') {
                  footerButtons = this.$parent.$refs.modal.$children[0].$refs.footerButtons;
                }
                if (typeof footerButtons == 'undefined') {
                  return;
                }
              }
              let buttons = footerButtons.$el.querySelectorAll('.button:enabled');
              this.focusButton(buttons, direction);
            }
            break;
            //ESC
          case 27:
            if (this.show && this.canCloseModal) {
              e.preventDefault();
              e.stopPropagation();
              this.close();
            }
            break;
            //F5
          case 116:
            e.preventDefault();
            e.stopPropagation();
            this.$emit('refresh');
            return false;
            break;
            //Enter or Tab
          case 9:
          case 13:
            //Needed for Enter on button to submit the form and textarea
            const enterIgnoreFocus = ['BUTTON', 'TEXTAREA'];
            if (e.keyCode === 13 && enterIgnoreFocus.includes(document.activeElement.tagName)) {
              return;
            }
            this.focusNextField(e);
            break;
        }
      }
    },
    positionClasses() {
      let classes = [];
      if (this.position) {
        classes.push('absolute');
        if (typeof this.position.top != 'undefined') {
          let positionTop = this.position.top + 5;
          classes.push(`top-${positionTop}`);
        }
        if (typeof this.position.bottom != 'undefined') {
          let positionBottom = this.position.bottom + 5;
          classes.push(`bottom-${positionBottom}`);
        }
        if (typeof this.position.left != 'undefined') {
          let positionLeft = this.position.left + 5;
          classes.push(`left-${positionLeft}`);
        }
        if (typeof this.position.right != 'undefined') {
          let positionRight = this.position.right + 5;
          classes.push(`right-${positionRight}`);
        }
      }
      return classes.join(' ');
    },
    modalClasses() {
      let classes = [];
      if (window.modals.active === this.uid) {
        classes.push('active-modal');
      }
      return classes;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler: (show) => {
        if (show) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = null
        }
      }
    },
    updateUid(newValue, oldValue) {
      if (newValue) {
        this.uid = window.modals.active;
      }
    }
  },
  created() {
    // stopClosingModal
    EventBus.$on('stopCloseModal', () => {
      this.canCloseModal = false;
    });
    // proceedCloseModal
    EventBus.$on('proceedCloseModal', () => {
      this.canCloseModal = true;
    });
  },
  mounted() {
    window.modals.count++;
    // set url
    if (this.url.length) {
      this.uid = this.url;
    }
    // set uid
    window.modals.list.push(this.uid);
    // set active
    window.modals.active = this.uid;
    EventBus.$emit('modal-opened', this.uid);
  },
  unmounted() {
    window.modals.count--;
    window.modals.list.splice(window.modals.list.indexOf(this.uid), 1);
    // set active
    const [last] = window.modals.list.slice(-1);
    window.modals.active = last;
    EventBus.$emit('modal-closed', this.uid);
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  }
}
</script>
