<template>
  <aside class="main-nav">
    <ul class="overflow-y-auto overflow-x-hidden">
      <li v-if="searchMenu" :title="$trans('global.search')">
        <beekman-dynamic-modal-button ref="searchMenu"
                                      key="searchMenu"
                                      element=""
                                      :route-url="'search-menu.index'"
                                      :modalType="'overlay'"
                                      styling="text-left">
          <div class="flex justify-center items-center">
            <i class="w-5 h-5 text-center text-sm fal fa-search"></i>
            <span class="ml-2 text" style="width: 6.21rem">
              {{$trans('global.search')}}
            </span>
          </div>
        </beekman-dynamic-modal-button>
      </li>
      <!-- Items Section -->
      <li v-for="menuItem in menuItems" :key="menuItem.text" :title="trans(menuItem.text)">
          <template v-if="menuItem.newTab === false">
            <beekman-nav-link :href="menuItem.route"
                              :only="['blocks', 'breadcrumbs']">
              <div class="flex justify-center items-center">
                <i class="w-5 h-5 text-center text-sm"
                   :class="menuItem.icon"></i>
                <span class="ml-2 text" style="width: 6.21rem">
                      {{menuItem.text}}
                </span>
              </div>
            </beekman-nav-link>
          </template>
          <template v-else>
              <a :href="menuItem.route" target="_blank">
                  <div class="flex justify-center items-center">
                      <i class="w-5 h-5 text-center text-sm"
                         :class="menuItem.icon"></i>
                      <span class="ml-2 text" style="width: 6.21rem">
                      {{menuItem.text}}
                </span>
                  </div>
              </a>
          </template>
      </li>
      <li v-if="menuItems.length == 0" title="Dashboard">
        <a :href="this.$route('dashboard')">
          <div class="flex justify-center items-center">
            <i class="w-5 h-5 text-center text-sm fal fa-home"></i>
            <span class="ml-2 text" style="width: 6.21rem;">Dashboard</span>
          </div>
        </a>
      </li>
    </ul>
  </aside>
</template>
<script>
import BeekmanNavLink from '@ui/Components/NavLink.vue';
import BeekmanDynamicModalButton from "@ui/Components/Buttons/DynamicModalButton.vue";

export default {
  components: {
    BeekmanNavLink,
    BeekmanDynamicModalButton,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    menuCollapsed: {
      default: false
    },
    searchMenu: {
      default: false
    },
  },
}
</script>
